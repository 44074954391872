/**
 /**
 * MyFarm map component.
 *
 * At the moment this is a directive without isolate scope due to the massive number of external linkages here
 * These need to be moved into services so this can become a proper component with isolate scope
 */
angular.module('FmsApp').directive('myfarmMap', function() {
  return {
    templateUrl:
      '/static/assets/khapps/fms_app/myfarm/map/myfarm-map.component.html',
    controller: function(
      $scope,
      $rootScope,
      $filter,
      $khMessage,
      $mdDialog,
      $route,
      leafletData,
      FieldBoundaryDialogService,
      MapSpeedDialOptions,
      FarmConstantsService,
      FarmFieldFactory,
      FieldPlotGeoFormatResource,
      GenericMapService,
      SatelliteProcessedDataListFactory,
      FieldPlotCropCountFactory,
      LegacyOperationFactory,
      DateTimeHelperFactory,
      SoilTestFactory,
      SatellitePublishedDatasetResource,
      SatelliteDetailDialogService,
      TeamSelectionService,
      FarmFiltersService,
      PlatformSettingsService,
      PlotDialogService,
      FarmEditDialogService,
      FarmFieldDialogService,
      StoreEditDialogService,
      SoilSampleEditDialogService,
      ErrorLoggingService,
      MyFarmMapFeatureSelectionService,
      GeoUtilsService,
      MyFarmMapService,
      FMSGenericHelperService,
      FarmDialogService,
      FieldResource,
      PlotResource,
      FieldDialogService
    ) {
      $scope.service = MyFarmMapService;

      // we should move the sidebars to components - until we do this enabled edit functionality in the sidebar
      $scope.showEdit = true;

      /**
       * Type of the current marker to be added to the map
       */
      let markerType;

      /**
       * Currently selected fieldcrop.
       * @type {null}
       */
      let selectedPlot = null;

      /**
       * Currently selected farm field
       * @type {null}
       */
      let selectedField = null;

      /**
       * Currently selected soil sample
       * @type {null}
       */
      let sample = null;

      /**
       * Currently selected store
       * @type {null}
       */
      let selectedStore = null;

      $scope.show_hide_layer_transparency = false;
      $scope.sort_layer_by_date = true;
      $scope.$on('leafletDirectiveMap.landingMap.load', function(event) {
        $scope.isLoaded = true;
      });
      $scope.toggleLayerOrder = function() {
        $scope.sort_layer_by_date = !$scope.sort_layer_by_date;
      };
      $scope.toggleLayerTransparency = function() {
        $scope.show_hide_layer_transparency = !$scope.show_hide_layer_transparency;
      };
      $scope.showSatelliteImages = false;
      $scope.mapSpeedDialOptions = MapSpeedDialOptions;

      $rootScope.apiCallCount = -2;
      $rootScope.sub_app_page_id = '';
      let mapid = 'landingMap';
      FMSGenericHelperService.appSideBar('farm', null);

      let lastFilter;

      // We communicate with the map service via RxJS observables
      let subs = [
        /**
         * Load the map when the farm filters change. This will happen once initially once the filters are
         * initialised, and from when on every time the filters are changed.
         */
        FarmFiltersService.get$().subscribe(mainFarmFilterChanged),
        // do something when we draw a marker
        MyFarmMapService.drawMarker$.subscribe(markerDrawn),
        // do something when we draw a polygon
        MyFarmMapService.drawPolygon$.subscribe(polygonDrawn),
        // do something when we select a feature
        MyFarmMapService.selection$.subscribe(function(selection) {
          MyFarmMapFeatureSelectionService.selectionChanged(selection, $scope);
        }),
      ];

      // which means we need to clean up the subscriptions on exit
      $scope.$on('$destroy', function() {
        _.each(subs, function(sub) {
          sub.dispose();
        });
      });

      buildBaseMapLayers();

      /**
       * The top nav team/farm/field filter has changed
       * @param filter
       */
      function mainFarmFilterChanged(filter) {
        if (filter) {
          // reload map if it's not been loaded yet or if the growing season changes
          if (
            !lastFilter ||
            lastFilter.team_slug !== filter.team_slug ||
            lastFilter.growing_season !== filter.growing_season
          ) {
            MyFarmMapService.reload(filter, true).then(function() {
              lastFilter = filter;
            });
          } else {
            if (filter.farm_slug !== lastFilter.farm_slug) {
              MyFarmMapService.zoomToFarm(filter.farm_slug);
            }

            if (
              filter.field_slug &&
              filter.field_slug !== lastFilter.field_slug
            ) {
              MyFarmMapService.zoomToField(filter.field_slug, filter.farm_slug);
            }

            lastFilter = filter;
          }
        }
      }

      $rootScope.$on('editedSoilAnalysisResults', function(object) {
        SoilTestFactory.query(
          {
            sample:
              MyFarmMapService.selectedFeature.feature.properties
                .soil_sample_slug
          },
          function(result_data) {
            $scope.soilTests = result_data;
          }
        );
      });

      // FIXME this is probably no longer needed - remove if sure
      FarmConstantsService.promise.then(result_data => {
        $rootScope.teams = result_data.teams;
        $rootScope.soils = result_data.soils;
        //$rootScope.farms = result_data.farm_list;
        $rootScope.planting_season_list = result_data.planting_season_list;
        $rootScope.crops = result_data.crops;
        $rootScope.capacity_units = result_data.capacity_units;
        $rootScope.yield_units = result_data.yield_units;
        $rootScope.store_types = result_data.store_types;
        $rootScope.cold_store_types = result_data.cold_store_types;
        $rootScope.depth_units = result_data.depth_units;
        $rootScope.firms = result_data.firms;
        $rootScope.destinations = result_data.destinations;
        $rootScope.harvest_options = [
          { name: 'Yes', value: 'Yes' },
          { name: 'No', value: 'No' }
        ];
        $rootScope.called_fms_contants = true;
      });

      //for editing layers
      $scope.cancelFeature = function() {
        MyFarmMapService.cancelEditedFeature();
        $rootScope.editFeatureMode = false;
      };

      //
      // Save the selected feature
      //
      $scope.saveFeature = function() {
        let feature = MyFarmMapService.selectedFeature;

        const boundary = GeoUtilsService.getBoundaryFromFeature(feature);
        let validArea = true;
        if (feature.edited) {
          validArea = isAreaValid(boundary.area);
        }

        if (validArea) {
          $rootScope.editFeatureMode = false;
          switch (feature.options.layer) {
            case 'FieldLayer':
              let isFieldValid = polygonValidate({
                layer: feature,
                selectedField: selectedField,
                type: 'field'
              });

              if (isFieldValid) {
                FieldDialogService.openEditDialog(feature.feature.properties.id,
                    boundary)
                    .subscribe((field) => {
                      if (field) {
                        feature.feature.properties = field;
                        feature.feature.geometry.coordinates = [boundary.bounds];
                        FarmFiltersService.fieldUpdated();
                        MyFarmMapService.updateFieldFeature(feature.feature);
                      }
                      MyFarmMapService.cancelEditedFeature();
                    });
              } else {
                feature.editing.enable();
                MyFarmMapService.drawOverlayLayer.removeLayer(feature);
                $khMessage.show('Please do not draw overlapping fields!');
                $scope.cancelFeature();
              }
              break;
            case 'FieldCropLayer':
              selectedPlot.planting_area = boundary.area;
              selectedPlot.boundary = boundary;
              const center = feature.getBounds().getCenter();
              selectedPlot.centroid = [center.lng, center.lat];

              let isPlotValid = polygonValidate({
                layer: feature,
                selectedPlot: selectedPlot,
                type: 'crop'
              });
              if (isPlotValid) {
                showCropEditDialog(selectedPlot);
              } else {
                feature.editing.enable();
                MyFarmMapService.drawOverlayLayer.removeLayer(feature);
                $khMessage.show('Please do not draw overlapping crops!');
                $scope.cancelFeature();
              }
              break;
            default:
              break;
          }
        } else {
          $scope.cancelFeature();
        }
      };

      //
      // use field boundary and save the field crop
      //
      $scope.useFieldBoundaryAndSaveFeature = function() {
        FieldResource.get({
          id: selectedPlot.field_id
        }).$promise.then(function(field) {
          selectedPlot.planting_area = field.boundary.area;
          selectedPlot.boundary = field.boundary;
          selectedPlot.centroid = field.centroid;

          $rootScope.editFeatureMode = false;
          showCropEditDialog(selectedPlot);
        });
      };

      function isAreaValid(area) {
        return !(area >= 999.9999);
      }

      /**
       * Show the field crop edit dialog for the selected plot
       */
      function showCropEditDialog(plot) {
        PlotDialogService.open(plot).subscribe(function(geoJsonPlot) {
          if (geoJsonPlot) {
            $khMessage.show('Created successfully', 3000);
            MyFarmMapService.updateFieldCropFeature(geoJsonPlot);
            selectedPlot = geoJsonPlot.properties;
            MyFarmMapFeatureSelectionService.selectionChanged(
              { feature: geoJsonPlot, options: { layer: 'FieldCropLayer' } },
              $scope
            );
          }

          MyFarmMapService.cancelEditedFeature();
        });
      }

      $scope.data_download = function() {
        $mdDialog
          .show({
            controller: 'GeoDataExportController',
            templateUrl:
              '/static/assets/khapps/geodataexport/GeoDataExportOptionsForm.html',
            parent: angular.element(document.body),
            animation: true,
            scope: $scope,
            preserveScope: true,
            clickOutsideToClose: true,
            locals: {
              teams: $rootScope.teams
            }
          })
          .then(function(response) {});
      };

      $scope.print = function() {
        $mdDialog.show({
          controller: 'GeoDataPrintController',
          templateUrl:
            '/static/assets/khapps/geodataprint/GeoDataPrintOptionsForm.html',
          parent: angular.element(document.body),
          animation: true,
          scope: $scope,
          multiple: true,
          preserveScope: true,
          clickOutsideToClose: true,
          locals: {
            teams: $rootScope.teams,
            fields: MyFarmMapService.geodata.field,
            khmapprintserver: $scope.khmapprintserver
          }
        });
      };

      $scope.data_upload = function() {
        FieldBoundaryDialogService.show();
      };

      $scope.shareObject = function(object_type, object_slug) {
        $mdDialog.show({
          controller: 'ShareWithAddEditController',
          templateUrl:
            '/static/assets/khapps/profiles/share_with/partials/share_with_form.html',
          parent: angular.element(document.body),
          animation: true,
          scope: $scope,
          multiple: true,
          preserveScope: true,
          clickOutsideToClose: true,
          locals: { object_slug: object_slug, object_type: object_type }
        });
      };

      $scope.viewDetailWeather = function(current_date) {
        let dialogTitle = 'Weather: ';
        let object_type, object_slug;

        if (
          angular.isDefined(
            MyFarmMapService.selectedFeature.feature.properties.field_slug
          )
        ) {
          dialogTitle =
            dialogTitle +
            MyFarmMapService.selectedFeature.feature.properties.field_name;
          object_type = 'field';
          object_slug =
            MyFarmMapService.selectedFeature.feature.properties.field_slug;
        } else {
          dialogTitle =
            dialogTitle +
            MyFarmMapService.selectedFeature.feature.properties.farm_name;
          object_type = 'farm';
          object_slug =
            MyFarmMapService.selectedFeature.feature.properties.farm_slug;
        }
        $mdDialog.show({
          controller: 'DetailWeatherViewController',
          templateUrl:
            '/static/assets/khapps/weather/apps/detail_weather_view/DetailWeatherView.html',
          scope: $scope,
          multiple: true,
          preserveScope: true,
          clickOutsideToClose: false,
          locals: {
            dialogTitle: dialogTitle,
            object_slug: object_slug,
            object_type: object_type
          }
        });
      };

      $scope.deleteSatelliteLayer = function(object_slug) {
        SatellitePublishedDatasetResource.delete(
          { publish_slug: object_slug },
          function(result_data) {
            $khMessage.show($filter('translate')('deleted_successfully'));
          },
          ErrorLoggingService.genericAPIError
        );
      };

      function create_field_progress_chart(process_name) {
        let overlayLayers = $scope.layers.overlays;
        for (let prop in overlayLayers) {
          let overlay_layer = overlayLayers[prop];
          if (overlay_layer.group === process_name) {
            $scope.uav_object_name = prop;
            $scope.uav_object_slug = overlay_layer.slug;
            break;
          }
        }
      }

      $scope.changeSelectedLayer = function(visible_layer_key, new_object) {
        if ($scope.uav_object_process_name !== new_object.group) {
          $scope.uav_object_process_name = new_object.group;
          create_field_progress_chart($scope.uav_object_process_name);
        }
        if (new_object.slug !== $scope.uav_object_slug) {
          $scope.uav_object_slug = new_object.slug;
        }
        $scope.layers['overlays'][$scope.uav_object_name].visible = false;
        $scope.uav_object_name = visible_layer_key;
        $scope.layers['overlays'][$scope.uav_object_name].visible = true;
      };

      $scope.informationSatelliteLayer = function(
        field_slug,
        object_slug,
        process_name
      ) {
        SatelliteDetailDialogService.show(
          $scope,
          field_slug,
          object_slug,
          process_name
        );
      };


      $scope.showSatelliteDataSubscriptionButton = true;
      $scope.satelliteSubscription = function(feature, objectType) {
        let objectFeature = angular.copy(feature);
        let objectSlug = objectFeature.properties.slug;
        objectFeature.properties = {};
        let subscriptionDict = {
          team: TeamSelectionService.getCurrentSlug(),
          field: {
            field_slug: objectSlug,
            type: 'FeatureCollection',
            features: [objectFeature]
          },
          subscription_duration: 12
        };
        $mdDialog.show({
          controller: 'SatelliteServiceSubscriptionController',
          templateUrl:
            '/static/assets/khapps/ksat/SatelliteServiceSubscriptionForm.html',
          parent: angular.element(document.body),
          animation: true,
          multiple: true,
          scope: $scope,
          preserveScope: true,
          clickOutsideToClose: false,
          locals: { subscription_dict: subscriptionDict }
        });
      };

      // FIXME this is used in the field sidebar - which needs to become a component
      $scope.edit_farmfield = function() {
        let selectedFeature = MyFarmMapService.editSelectedFeature();
        let field_slug = selectedFeature.feature.properties.slug;
        FarmFieldFactory.get({ field_slug: field_slug }, function(
          success_data
        ) {
          selectedFeature.editing.enable();
          selectedField = success_data;
          $rootScope.editFeatureMode = true;
          $rootScope.editFeatureType = 'field';
        });
      };

      // FIXME as is this.
      $scope.edit_fieldplotcrop = function() {
        let selectedFeature = MyFarmMapService.editSelectedFeature();
        const id = selectedFeature.feature.properties.id;
        PlotResource.get({ id }).$promise.then(function(plot) {
          selectedPlot = plot;

          selectedPlot.farmfieldfeature = MyFarmMapService.getFeature(
            'field',
            'id',
            selectedPlot.field_id
          );
          selectedFeature.editing.enable();
          $rootScope.editFeatureMode = true;
          $rootScope.editFeatureType = 'fieldcrop';
        });
      };
      /**
       * Set up the various overlay layers and map
       */
      function buildBaseMapLayers() {
        if (ie_11) {
          $scope.IS_IE = true;
        }

        leafletData.getMap(mapid).then(function(map) {
          $scope.show_overlay_layer_list_EmptyState = true;
          $scope.show_user_overlay_layer_list_EmptyState = true;
          let omsOptions = {
            legWeight: 5,
            nearbyDistance: 50,
            keepSpiderfied: true
          };
          $scope.oms = new L.OverlappingMarkerSpiderfier(map, omsOptions);

          $scope.legend = L.control({ position: 'bottomleft' });

          $scope.sidebar = L.control.sidebar('mapSidebar', {
            position: 'left'
          });
          map.addControl($scope.sidebar);

          $scope.bottombar = L.control.bottombar('mapBottombar', {
            position: 'bottom'
          });
          map.addControl($scope.bottombar);

          $scope.locate = L.control.locate({ position: 'topleft' });
          map.addControl($scope.locate);

          $scope.zoombox = L.control.boxzoom({ position: 'topleft' });
          map.addControl($scope.zoombox);

          let optionsOCS = {
            // 3c38d15e76c02545181b07d3f8cfccf0 REPLACE WITH YOUR API-KEY. This key might go away any time!
            key: '72491cbd40544ce1a4158ff6a3bb742d',
            limit: 10
          };
          L.Control.OpenCageSearch.geocoder(optionsOCS);
          L.Control.openCageSearch(optionsOCS).addTo(map);

          L.control.mousePosition().addTo(map);
          L.control.scale().addTo(map);
        });
        // Because leaflet will barf if there's no valid center set
        // In practise this will always be the case
        if (!PlatformSettingsService.DEFAULT_CENTER_LAT) {
          $scope.center = {
            lat: 53.6,
            lng: -1.6,
            zoom: 8
          };
        } else {
          $scope.center = {
            lat: parseFloat(PlatformSettingsService.DEFAULT_CENTER_LAT),
            lng: parseFloat(PlatformSettingsService.DEFAULT_CENTER_LONG),
            zoom: parseFloat(PlatformSettingsService.DEFAULT_CENTER_ZOOM)
          };
        }

        angular.extend($scope, {
          defaults: {
            maxZoom: 22,
            minZoom: 2,
            worldCopyJump: true,
            doubleClickZoom: true,
            scrollWheelZoom: true
          },
          layers: {
            baselayers: {
              googleHybrid: {
                name: 'Google Hybrid',
                layerType: 'HYBRID',
                type: 'google'
              },
              googleRoadmap: {
                name: 'Google Streets',
                layerType: 'ROADMAP',
                type: 'google'
              }
            },
            tileLayerOptions: {
              opacity: 0.9,
              detectRetina: true,
              reuseTiles: true
            },
            overlays: {
              draw: {
                name: 'draw',
                type: 'markercluster',
                visible: true,
                layerParams: {
                  showOnSelector: false
                }
              }
            },
            controls: {
              zoomControl: { zoomControl: false, position: 'bottomright' },
              scale: { scale: true, position: 'bottomright' },
              draw: {
                draw: {
                  marker: false,
                  polyline: false,
                  polygon: false,
                  rectangle: false,
                  circle: false
                }
              }
            }
          }
        });

        leafletData.getMap(mapid).then(function(map) {
          map.invalidateSize.bind(map);
          setTimeout(map.invalidateSize.bind(map));
        });
      }

      $rootScope.toggleBottomBar = function(objectFeature) {
        $scope.bbox = objectFeature.properties.bounds_calculated;
        GenericMapService.remove_all_overlay_layers($scope);
        GenericMapService.loadSatelliteData(
          $rootScope,
          $scope,
          SatelliteProcessedDataListFactory
        );
        let objKeys = Object.keys($scope.layers.overlays);
        $scope.toggleLayerTransparency();
        $scope.hideLayer();
        if ($scope.sidebar.isVisible()) {
          $scope.sidebar.toggle();
        }
        if (!$scope.bottombar.isVisible()) {
          $scope.bottombar.toggle();
        }
        $scope.bottombar_path =
          '/static/assets/khapps/fms_app/partials/mapSidebar/information_bottombar.html';
      };

      $rootScope.satellite_data_view = function() {
        if ($scope.sidebar.isVisible()) {
          $scope.sidebar.toggle();
        }
        if (!$scope.bottombar.isVisible()) {
          $scope.bottombar.toggle();
        }
        $scope.bottombar_path =
          '/static/assets/khapps/fms_app/partials/mapSidebar/information_bottombar.html';
      };

      $scope.toggleSideBar = function() {
        if (!$scope.sidebar.isVisible()) {
          $scope.sidebar.toggle();
        }
        $scope.sidebar_path =
          '/static/assets/khapps/fms_app/partials/mapSidebar/information_sidebar.html';
      };

      // FIXME where is $scope.layers set?
      $scope.toggleOverlayLayer = function(item) {
        $scope.layers.overlays[item.name].visible = !$scope.layers.overlays[
          item.name
        ].visible;
      };

      $scope.deselectAllLayers = function() {
        for (let key in $scope.layers.overlays) {
          $scope.layers.overlays[key].visible = false;
        }
      };

      $scope.selectAllLayers = function() {
        for (let key in $scope.layers.overlays) {
          $scope.layers.overlays[key].visible = true;
        }
      };

      $scope.selectLatestLayers = function() {
        for (let key in $scope.layers.overlays) {
          $scope.layers.overlays[key].visible = true;
        }
      };

      $scope.unselectLatestLayers = function() {
        for (let key in $scope.layers.overlays) {
          $scope.layers.overlays[key].visible = false;
        }
      };

      $scope.viewfieldinformation = function() {
        //$scope.sidebar.toggle();
        $scope.sidebar_path =
          '/static/assets/khapps/fms_app/partials/mapSidebar/field_sidebar.html';
      };

      $scope.removeAllOverlayLayers = function() {
        for (let key in $scope.layers.overlays) {
          delete $scope.layers.overlays[key];
        }
      };

      $scope.hideLayer = function() {
        MyFarmMapService.setPlotStyle(5, 0);
        MyFarmMapService.setFieldStyle(5, 0);
      };

      $scope.showLayer = function() {
        MyFarmMapService.setPlotStyle(2, 0.4);
        MyFarmMapService.setFieldStyle(2, 0.1);
      };

      //
      // Drawing on Map
      //

      /**
       * Called when a speeddial option is selected. It will call a function defined in MapSpeedDialOptions
       * @param name
       */
      $scope.speedDialSelected = function(name) {
        $scope[name]();
      };

      $scope.draw_farm = function() {
        markerType = 'farm';
        add_marker();
      };

      $scope.draw_soilsample = function() {
        markerType = 'soil_sample';
        add_marker();
      };

      $scope.draw_field = function() {
        markerType = 'field';
        add_polygon('cyan');
      };

      $scope.draw_crop = function() {
        markerType = 'crop';
        add_polygon('white');
      };

      $scope.draw_marker = function() {
        add_marker();
      };

      $scope.draw_store = function() {
        markerType = 'store';
        $scope.draw_marker();
      };

      function add_marker() {
        leafletData.getMap(mapid).then(function(map) {
          let marker = new L.Draw.Marker(map, {});
          marker.enable();
        });
      }

      function add_polygon(fillColour) {
        leafletData.getMap(mapid).then(function(map) {
          let polygon = new L.Draw.Polygon(map, {
            showArea: true,
            allowIntersection: false,
            shapeOptions: {
              color: fillColour
            }
          });
          polygon.enable();
        });
      }

      //
      // The farm marker has been placed on the map
      //
      function farmMarkerPlaced(details) {
        const geocoding = details.geocode;
        const farm = {
          location: [geocoding.longitude, geocoding.latitude],
          address: {
            address1: geocoding.region,
            address2: geocoding.sub_region,
            address3: geocoding.sub_sub_region,
            address4: geocoding.location,
            country_code: geocoding.country,
            address_code: geocoding.post_code
          },
          team_id: TeamSelectionService.getCurrentId()
        };
        FarmDialogService.open(farm).subscribe(function(farmGeojson) {
          if (farmGeojson) {
            $khMessage.show($filter('translate')('created_successfully'));
            FarmEditDialogService.updateFarmLayer(farmGeojson);
          }
        });
        MyFarmMapService.drawOverlayLayer.addLayer(details.layer);
      }

      //
      // The soil sample marker has been placed on the map
      //
      function soilSampleMarkerPlaced(details) {
        let drawLayer = details.layer;
        sample = {};
        let point = drawLayer.getLatLng();
        let field_results = MyFarmMapService.findPointInLayer('field', point);
        if (field_results.length) {
          let field = field_results[0];
          sample.field_id = field.feature.properties.id;
          sample.location = [
            details.geocode.longitude,
            details.geocode.latitude
          ];
          MyFarmMapService.drawOverlayLayer.addLayer(drawLayer);

          SoilSampleEditDialogService.showForSample(sample).then(sample => {
            // create a feature for the newly created sample (in the newer APIs we don't return
            // geojson)
            MyFarmMapService.updateSoilSampleFeature({
              geometry: {
                type: 'Point',
                coordinates: sample.location
              },
              type: 'Feature',
              properties: sample
            });
          });
        } else {
          MyFarmMapService.drawOverlayLayer.removeLayer(drawLayer);
          $khMessage.show('Please mark soil sample location inside field');
        }
      }

      function storeMarkerPlaced(details) {
        let drawLayer = details.layer;
        selectedStore = {};
        selectedStore.location = details.geocode.location;
        if (TeamSelectionService.getCurrentSlug() !== 'All Teams') {
          selectedStore.team = TeamSelectionService.getCurrentSlug();
        }
        MyFarmMapService.drawOverlayLayer.addLayer(drawLayer);
        StoreEditDialogService.show(selectedStore, details.geocode);
      }

      /**
       * A new field has been drawn
       * @param details target event details
       */
      function newFieldPolygonDrawn(details) {
        let feature = details.layer;

        const boundary = GeoUtilsService.getBoundaryFromFeature(feature);
        let farm_id;
        let selectedFarm = FarmFiltersService.getSelectedFarm();
        if (selectedFarm) {
          farm_id = selectedFarm.id;
        }

        if (GeoUtilsService.isAreaValid(boundary.area)) {
          MyFarmMapService.drawOverlayLayer.addLayer(feature);
          FieldDialogService.openCreateDialog(boundary, farm_id).subscribe( (field) => {
            const feature = {
                geometry: { type: 'Polygon', coordinates: [boundary.bounds] },
                type: 'Feature',
                properties: field
            }
            MyFarmMapService.updateFieldFeature(feature);
            FarmFiltersService.fieldUpdated();
          });
        } else {
          MyFarmMapService.drawOverlayLayer.removeLayer(feature);
          $khMessage.show('Please draw field less than 1000ha');
          $mdDialog.hide();
        }
      }

      //
      // A new field crop has been drawn
      //
      function generatePlotName(growingSeason, count) {
        const plotPrefix = PlatformSettingsService.PLOT_NAME_PREFIX || '';
        const plotSuffix = PlatformSettingsService.PLOT_NAME_SUFFIX || '';
        const plotNumber = ('0000' + String(count + 1)).slice(-4);
        const plotName = plotPrefix + growingSeason + plotSuffix + plotNumber;

        return plotName;
      }
      function isAllSeasonsSelected() {
        return $rootScope.selected_growing_season === 'All Seasons';
      }
      function newPlotPolygonDrawn(drawLayer, intersectingGeoJsonField) {
        const latLng = drawLayer.getLatLngs();
        const drawArea = L.GeometryUtil.geodesicArea(latLng);
        const plotArea = Number((drawArea / 10000).toFixed(4));

        if (GeoUtilsService.isAreaValid(plotArea)) {
          const rawSeason = isAllSeasonsSelected()
            ? $scope.planting_season_current
            : $rootScope.selected_growing_season.value;
          const season = rawSeason.replace('/', '-');
          FieldPlotCropCountFactory.get({ season }).$promise.then(function(
            data
          ) {
            const name = generatePlotName(season, data.count);
            const fieldId = intersectingGeoJsonField.feature.properties.id;
            const bounds = drawLayer.toGeoJSON().geometry.coordinates[0];
            const center = drawLayer.getBounds().getCenter();
            const centroid = [center.lng, center.lat];

            const plot = {
              name,
              field_id: fieldId,
              boundary: {
                bounds,
                area: plotArea
              },
              open: true,
              planned_planting_date: new Date(),
              planting_area: plotArea,
              growing_season: [rawSeason],
              centroid
            };

            MyFarmMapService.drawOverlayLayer.addLayer(drawLayer);
            showCropEditDialog(plot);
          });
        } else {
          MyFarmMapService.drawOverlayLayer.removeLayer(drawLayer);
          $khMessage.show('Please draw crop less than 1000ha');
        }
      }

      //
      // A marker has been placed on the map
      //
      function markerDrawn(details) {
        if (markerType === 'farm') {
          farmMarkerPlaced(details);
        } else if (markerType === 'soil_sample') {
          soilSampleMarkerPlaced(details);
        } else if (markerType === 'store') {
          storeMarkerPlaced(details);
        }
        markerType = null;
      }

      //
      // A polygon has been drawn on the map
      //
      function polygonDrawn(details) {
        let drawLayer = details.layer;
        MyFarmMapService.drawOverlayLayer.addLayer(drawLayer);
        let drawnFieldLatLngs = drawLayer.getLatLngs();
        let countInside = 0;
        let countInsideFields = 0;
        let overlappingmutiplefields = 0;
        let intersectingfield;
        for (let i = 0; i < drawnFieldLatLngs.length; i++) {
          let polygon_vertex = drawnFieldLatLngs[i];
          intersectingfield = MyFarmMapService.findPointInLayer('field', [
            polygon_vertex.lng,
            polygon_vertex.lat
          ]);
          if (intersectingfield.length) {
            countInside++;
            if (countInside === 1) {
              countInsideFields =
                intersectingfield[0].feature.properties.field_name;
            } else if (
              countInsideFields !==
              intersectingfield[0].feature.properties.field_name
            ) {
              overlappingmutiplefields = 1;
              continue;
            }
            selectedPlot = {};
          }
        }

        if (countInside === 0) {
          newFieldPolygonDrawn(details);
        } else if (
          countInside === drawnFieldLatLngs.length &&
          overlappingmutiplefields === 0
        ) {
          newPlotPolygonDrawn(drawLayer, intersectingfield[0]);
        } else {
          drawLayer.editing.enable();
          MyFarmMapService.drawOverlayLayer.removeLayer(drawLayer);
          $khMessage.show('Please draw non overlapping fields/crops');
          $mdDialog.hide();
        }
      }

      //
      // Weather a field drawn(edit) on map is overlapping with others.
      //
      function polygonValidate(details) {
        /*
                details: {layer: feature, selectedPlot: selectedPlot, type: "field"/"crop"}
                */
        let drawLayer = details.layer;
        let drawnLatLongs = drawLayer.getLatLngs();
        let intersectingPlot;
        let intersectingField;

        for (let i = 0; i < drawnLatLongs.length; i++) {
          let singleLatLong = drawnLatLongs[i];

          intersectingPlot = MyFarmMapService.findPointInLayer('fieldcrop', [
            singleLatLong.lng,
            singleLatLong.lat
          ]);
          intersectingField = MyFarmMapService.findPointInLayer('field', [
            singleLatLong.lng,
            singleLatLong.lat
          ]);

          if (details.type === 'crop') {
            // point is invalid if it is inside any other plot
            if (intersectingPlot.length) {
              if (
                intersectingPlot[0].feature.properties.slug !==
                details.selectedPlot.slug
              ) {
                return false;
              }
            }

            // point is invalid if it is inside any other field than its own
            if (intersectingField.length) {
              if (
                intersectingField[0].feature.properties.id !==
                details.selectedPlot.field_id
              ) {
                return false;
              }
            } else {
              // point is valid even if it lies on one of the corners of its field, but not inside
              let fieldCorners = _.map(
                details.selectedPlot.farmfieldfeature.geometry.coordinates[0],
                function(pnt) {
                  return JSON.stringify(pnt);
                }
              );
              if (
                fieldCorners.indexOf(
                  JSON.stringify([singleLatLong.lng, singleLatLong.lat])
                ) < 0
              ) {
                return false;
              }
            }
          } else if (details.type === 'field') {
            if (intersectingField.length) {
              if (
                intersectingField[0].feature.properties.slug !==
                details.selectedField.field_slug
              ) {
                return false;
              }
            }
          }
        }
        return true;
      }

      $scope.confirmFieldPlotCropDelete = function(slug) {
        let confirm = $mdDialog
          .confirm()
          .title($filter('translate')('common.delete_field_crop_confirmation'))
          .ariaLabel($filter('translate')('common.delete'))
          .ok($filter('translate')('yes'))
          .cancel($filter('translate')('no'));
        $mdDialog.show(confirm).then(function() {
          FieldPlotGeoFormatResource.delete(
            { slug: slug },
            function() {
              MyFarmMapService.fullReload();
              $scope.sidebar.toggle();
              $khMessage.show($filter('translate')('deleted_successfully'));
            },
            ErrorLoggingService.genericAPIError
          );
        });
      };

      $scope.viewSoilTest = function(testSlug) {
        $mdDialog.show({
          templateUrl:
            '/static/assets/khapps/fms_app/partials/soilanalysis/forms/soilresults_view_dialog.html',
          controller: 'ViewSoilTestResultsController',
          scope: $scope,
          preserveScope: true,
          locals: {
            slug: testSlug
          }
        });
      };

      $scope.editAssignedFields = function(gateway_slug, name) {
        $rootScope.selected_station_detail = {
          name: name,
          value: gateway_slug
        };
        if ($rootScope.farm_list[1]) {
          $mdDialog.show({
            controller: 'AddEditPWSAssignedFieldsController',
            skipHide: true,
            multiple: true,
            templateUrl:
              '/static/assets/khapps/weather/partials/forms/personalweatherstation/EditAssignedFieldsForm.html',
            clickOutsideToClose: false
          });
        } else {
          $khMessage.show('User does not have any Farms.');
        }
      };

      $scope.enableStationWeatherDataExportButton = true;
      $scope.OpenStationExportDialog = function(gateway_slug) {
        let dataFromParentController = {};
        dataFromParentController['slug'] = gateway_slug;
        $mdDialog.show({
          controller: 'PWSDataExportController',
          skipHide: true,
          multiple: true,
          templateUrl:
            '/static/assets/khapps/weather/partials/abstract/WeatherDataExport.html',
          parent: angular.element(document.body),
          locals: {
            dataFromParentController: dataFromParentController
          },
          clickOutsideToClose: false
        });
      };

      // do we need to zoom into a location?
      let params = $route.current.params;
      if (params && params.type) {
        MyFarmMapService.zoomToFeature(params.type, params.slug);
      }
    }
  };
});
