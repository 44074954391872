(function (angular) {
    'use strict';
    angular.module('GeoDataPrintApp')
        .factory('GeoDataPrintResource', GeoDataPrintResource);

    GeoDataPrintResource.$inject = ['$resource'];

    function GeoDataPrintResource ($resource) {
        var url = 'https://kisansatgeouat.kisanhub.com/mapfish/print/trials/buildreport.pdf';
        return $resource(url, {}, {
            save: {
                method: 'POST',
                headers: {'content-type': 'application/json'},
            }
        });
    }

})(angular);

(function (angular) {
    'use strict';
    angular.module('GeoDataPrintApp')
        .factory('GeoDataPrintFarmFieldResource', GeoDataPrintFarmFieldResource);

    GeoDataPrintFarmFieldResource.$inject = ['$resource'];

    function GeoDataPrintFarmFieldResource ($resource) {
        var url = '/api/v1.0/fms/farmfield/';
        return $resource(url, {}, {
            save: {
                method: 'POST'
            },
            get: {
                params: {field_slug: '@field_slug', crs: '@crs'},
                url: url +':field_slug/',
                method: 'GET'
            },
            update: {
                params: {field_slug: '@field_slug'},
                url: url +':field_slug/',
                method: 'PUT'
            },
            delete: {
                params: {field_slug: '@field_slug'},
                url: url +':field_slug/',
                method: 'DELETE'
            }
        });
    }

})(angular);