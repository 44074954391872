angular.module('FmsApp').service("FMSConstantsService", function () {

    this.viewTypes = function () {
        return {
            All: "All",
            MAP_VIEW: "mapview",
            TABLE_VIEW: "tableview"
        };
    };
});
