(function (angular) {
    'use strict';
    angular.module('GeoDataExportApp')
        .run(['$http', '$rootScope', '$routeParams', '$location', '$mdDialog', GeoDataExportRunConfig]);

    function GeoDataExportRunConfig($http, $rootScope, $routeParams, $location, $mdDialog) {
        $http.defaults.xsrfCookieName = 'csrftoken';
        $http.defaults.xsrfHeaderName = 'X-CSRFToken';
    }

})(angular);


