(function (angular) {
    'use strict';
    angular.module('GeoDataExportApp')
        .controller('GeoDataExportController', GeoDataExportController);

    GeoDataExportController.$inject = ['$scope', '$rootScope', '$filter', '$khMessage', '$mdDialog', 'GeoDataExportResource', 'teams'];

    function GeoDataExportController($scope, $rootScope, $filter, $khMessage, $mdDialog, GeoDataExportResource, teams) {
        setUpForm();

        function setUpForm() {
            $scope.team_list = teams;
            $scope.geodata_export_options_form = load_geodata_export_options_formly_fields($scope);
        }

        $scope.geodata_export = function () {
            GeoDataExportResource.get({
                team: $scope.geodata_export_options.team_slug,
                precision_ag: $scope.geodata_export_options.precision_ag
            }, function (success_data) {
                $khMessage.show('Your request to download GeoSpatial data is recorded. This will take a while. You will be notified by E-Mail as soon as data is ready to download.', 50000);
                $mdDialog.hide();
            }, function (error_data) {
                $khMessage.show('Failed to record your request to download GeoSpatial data please try again.', 50000);
            });
        };

        function load_geodata_export_options_formly_fields($scope) {
            return [
                {
                    className: 'flex-100',
                    type: 'searchable_select',
                    key: 'template_slug',
                    templateOptions: {
                        required: false,
                        multiple: false,
                        label: 'Select Device Template',
                        placeholder: 'Select device template',
                        valueProp: 'slug',
                        labelProp: 'name',
                        options: [{template_slug: 'trimble_aggps', name: 'Trimble AgGPS'}]
                    }
                },
                {
                    className: 'flex-100',
                    type: 'searchable_select',
                    key: 'team_slug',
                    id: 'team_id',
                    templateOptions: {
                        required: true,
                        multiple: false,
                        label: 'Select team',
                        placeholder: 'Select team',
                        valueProp: 'slug',
                        labelProp: 'name',
                        options: $scope.team_list
                    }
                },
                {
                    className: 'flex-100',
                    type: 'checkbox',
                    key: 'precision_ag',
                    id: 'precision_ag_id',
                    defaultValue: true,
                    templateOptions: {
                        disabled: true,
                        required: true,
                        label: 'Farm Machinery Compatibility',
                    }
                },
                // {
                //     className: 'flex-50',
                //     type: 'searchable_select',
                //     key: 'farm_slugs',
                //     templateOptions: {
                //         required: true,
                //         multiple: true,
                //         label: 'Select Farms',
                //         placeholder: 'Select farms',
                //         valueProp: 'slug',
                //         labelProp: 'name',
                //         options: $scope.farm_list
                //     },
                // },
                // {
                //     className: 'flex-50',
                //     type: 'searchable_select',
                //     key: 'field_slugs',
                //     templateOptions: {
                //         required: true,
                //         multiple: true,
                //         label: 'Select Fields',
                //         placeholder: 'Select fields',
                //         valueProp: 'slug',
                //         labelProp: 'name',
                //         options: $scope.field_list
                //     },
                // },
                // {
                //     className: 'flex-50',
                //     type: 'searchable_select',
                //     key: 'plot_slugs',
                //     templateOptions: {
                //         required: true,
                //         multiple: true,
                //         label: 'Select Plots',
                //         placeholder: 'Select Plots',
                //         valueProp: 'slug',
                //         labelProp: 'name',
                //         options: $scope.plot_list
                //     },
                // },
            ];
        }

    }

})(angular);
