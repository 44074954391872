angular.module('FmsApp').component('farmFieldForm', {
    templateUrl: '/static/assets/khapps/fms_app/myfarm/form/field/farm-field-form.component.html',
    bindings: {
        farmfield: '<',
        dialogTitle: '<'
    },
    controller: function ($filter, $mdDialog, $khMessage,
                          MyFarmMapService,
                          FarmFiltersService,
                          FarmConstantsService, FarmFieldResource, GenericUtilsService) {
        'use strict';

        var self = this;

        function loadForm() {
            return [
                {
                    className: 'flex-100',
                    type: 'searchable_select',
                    key: 'farm',
                    templateOptions: {
                        optionsAttr: 'bs-options',
                        ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                        valueProp: 'slug',
                        labelProp: 'name',
                        required: true,
                        label: 'Select Farm',
                        placeholder: 'Select farm',
                        options: FarmFiltersService.getFarms()
                    }
                },
                {
                    className: 'flex-50',
                    type: 'input',
                    key: 'field_name',
                    templateOptions: {
                        required: true,
                        label: 'Field Name',
                        placeholder: 'Field name',
                        maxlength: 100
                    },
                    validators: {
                        name: {
                            expression: function (viewValue, modelValue) {
                                return (modelValue && self.farmfield.farm &&
                                        !FarmFiltersService.checkFarmFieldName(self.farmfield.farm, modelValue,
                                                                               self.farmfield.field_slug));
                            },
                            message: '"You already have a field named "+ $viewValue'
                        }
                    }
                },
                {
                    className: 'flex-50',
                    type: 'input',
                    key: 'field_block',
                    templateOptions: {
                        label: 'Field Block',
                        placeholder: 'Field group block'
                    }
                },
                {
                    className: 'flex-100',
                    type: 'searchable_select',
                    key: 'soil_type',
                    templateOptions: {
                        optionsAttr: 'bs-options',
                        ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                        valueProp: 'value',
                        labelProp: 'name',
                        required: true,
                        label: 'Select Soil Type',
                        placeholder: 'Select soil type',
                        options: FarmConstantsService.soils
                    }
                },
                {
                    className: 'flex-50',
                    type: 'input',
                    key: 'workable_area',
                    templateOptions: {
                        required: true,
                        label: 'Workable Area (ha)',
                        placeholder: 'Workable area (ha)'
                    },
                    validators: {
                        name: {
                            expression: function (viewValue, modelValue) {
                                return (modelValue && modelValue <= self.farmfield.contingency_workable_area)
                            },
                            message: '$viewValue + " is too large'
                        }
                    }
                },

                {
                    className: 'flex-50',
                    type: 'input',
                    key: 'field_area',
                    templateOptions: {
                        disabled: true,
                        label: 'Field Area (ha)',
                        placeholder: 'Field area (ha)'
                    }
                },
                {
                    className: 'flex-100',
                    type: 'textarea',
                    key: 'notes',
                    templateOptions: {
                        label: 'Notes',
                        placeholder: 'Enter notes...',
                        rows: 3
                    }
                },
                {
                    className: 'flex-100',
                    type: 'checkbox',
                    key: 'make_default_field',
                    id: 'make_default',
                    defaultValue: false,
                    templateOptions: {
                        label: 'Set as default'
                    }
                }
            ];
        }
        self.form_fields = loadForm();

        self.cancel = function () {
            $mdDialog.cancel();
        };

        self.save = function () {
            let promise;
            if (!self.farmfield.field_slug) {
                promise = FarmFieldResource.save(self.farmfield).$promise;
            } else {
                promise = FarmFieldResource.update(self.farmfield).$promise;
            }
            promise.then(function (field_feature) {
                field_feature.properties.id = field_feature.properties.field_id;
                field_feature.properties.name = field_feature.properties.field_name;
                field_feature.properties.slug = field_feature.properties.field_slug;
                FarmFiltersService.fieldUpdated(field_feature.properties.field_slug);
                MyFarmMapService.updateFieldFeature(field_feature);
                $khMessage.show('Created successfully', 2000);
                $mdDialog.hide(field_feature);
            }, function(error) {
                if (error.status === 400) {
                    $khMessage.show(error.data.detail, 10000);
                } else {
                    GenericUtilsService.handleError($filter, $khMessage, error);
                }
            });
        }
    }
});
