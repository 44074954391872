angular.module('FmsApp').component('soilSampleSidebar', {
   templateUrl: '/static/assets/khapps/fms_app/myfarm/form/soil/sample/soil-sample-sidebar.component.html',
   bindings: {
      sample: '<'
   },
   controller: function(SoilSampleResource, KisanHubIconsResource, SoilSampleEditDialogService) {
       this.khIcon = KisanHubIconsResource.khIcons;
       this.editSample = () => {
            SoilSampleEditDialogService.showForSample(this.sample).then( (sample) => {
                this.sample = sample;
            });
       };

       this.$onChanges = () => {
            this.sample = SoilSampleResource.get({id: this.sample.id});
       };

   }
});
