angular.module('FmsApp').service("FMSSetUpHelperService", function () {

    this.getFilterDict = function ($filter, key, type, placeholder) {
        var dict = {};
        dict[key] = {
            id: type,
            placeholder: $filter('translate')(placeholder)
        };
        return dict;
    };
});
