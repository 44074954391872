angular.module('FmsApp').service("CropBookDialogService", function ($mdDialog) {
   this.show = function(plotSlug, plotName) {
       $mdDialog.show({
            controller: 'CropBookDetailController',
            templateUrl: '/static/assets/khapps/crop_book/CropBookDetailDialogue.html',
            skipHide: true,
            multiple: true,
            locals: {
                parentControllerData: {"plotData": plotSlug},
                DetailedChartsAPIData: {},
                plot_header_name: plotName
            },
            clickOutsideToClose: true
        });
    };

});