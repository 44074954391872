angular.module('FmsApp').directive('farmSidebarJs', function() {
  return {
    templateUrl:
      '/static/assets/khapps/fms_app/myfarm/map/sidebar/farm/farm-sidebar.directive.html',
    controller: function(
      $scope,
      MyFarmMapService,
      FarmEditDialogService,
      $filter,
      $mdDialog,
      FarmResource,
      FarmFiltersService
    ) {
      $scope.onItemChange = function(farmGeojson) {
        FarmEditDialogService.updateFarmLayer(farmGeojson);
      };

      $scope.subscribe = function(farm) {
        $scope.ObjectNotificationSubscription('Farm', farm.slug, farm.name);
      };

      $scope.farmDeleted = function() {
         FarmFiltersService.reload();
         MyFarmMapService.fullReload();
         $scope.sidebar.toggle();
      }
    }
  };
});
