angular.module('FmsApp').constant('DefinedMapLayers', {
    googleHybrid: {
        name: 'Google Hybrid', type: 'google', layerType: 'HYBRID', noWarp: true
    }, ESRI_satellite_layer: {
        name: "ESRI Satellite",
        type: "xyz",
        url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
        noWrap: true
    }, Esri_WorldGrayCanvas: {
        name: "ESRI World Gray",
        type: "xyz",
        url: 'http://server.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Base/MapServer/tile/{z}/{y}/{x}',
        maxZoom: 16,
        attribution: 'Tiles &copy; Esri &mdash; Esri, DeLorme, NAVTEQ',
        noWrap: true
    }, OSM: {
        name: "Openstreetmap",
        type: "xyz",
        url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
        noWrap: true
    }
});
