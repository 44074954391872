angular.module('FmsApp').component('plotSidebar', {
  templateUrl:
    '/static/assets/khapps/fms_app/myfarm/map/sidebar/plot/plot-sidebar.component.html',
  bindings: {
    plot: '<',
    editClick: '&',
    subscribeClick: '&',
    notifyClick: '&',
    plotDeleted: '&',
    shareClick: '&'
  },
  controller: function(
    PlotHasSatelliteImageryResource,
    $q,
    PlotResource,
    GenusResource,
    VarietyResource,
    FieldResource,
    PermissionEvaluatorService,
    FarmResource,
    PhoenixService,
    SoilTypeResource,
    TeamResource,
    UserResource,
    $rootScope,
    CropBookDialogService,
    DeletePlotService,
    $mdDialog
  ) {
    const self = this;

    self.updatedByUser = {};
    self.field = {};
    self.farm = {};
    self.team = {};
    self.canEdit = false;
    self.soilType = {};

    self.is_superuser = $rootScope.is_superuser === 'True';
    self.hasSatelliteImagery = false;

    this.$onChanges = () => {

      $q.all([
        PlotResource.get({ id: self.plot.id }).$promise,
        PlotResource.permission({ id: self.plot.id }).$promise,
        PlotHasSatelliteImageryResource.get({id: self.plot.id}).$promise,
      ])
        .then(function([plot, permission, SatelliteSubscription]) {
          self.plot = plot;
          self.canEdit = PermissionEvaluatorService.isReadWrite(
            permission.access
          );
          self.hasSatelliteImagery = SatelliteSubscription.has_satellite_subscription;

          const varietyPromise = plot.variety_id
            ? VarietyResource.get({ id: plot.variety_id })
            : Promise.resolve(null);

          return $q.all([
            UserResource.get({ id: plot.updated_by_id }).$promise,
            FieldResource.get({ id: plot.field_id }).$promise,
            GenusResource.get({ id: plot.crop_id }).$promise,
            SoilTypeResource.get({ id: plot.soil_type_id }).$promise,
            varietyPromise
          ]);
        })
        .then(function([user, field, genus, soilType, variety]) {
          self.updatedByUser = user;
          self.field = field;
          self.genus = genus;
          self.soilType = soilType;
          self.variety = variety;

          return FarmResource.get({ id: field.farm_id }).$promise;
        })
        .then(function(farm) {
          self.farm = farm;

          return TeamResource.get({ id: farm.team_id }).$promise;
        })
        .then(function(team) {
          self.team = team;
        });
    };

    this.satBook = function(object_type, object_slug) {
      $mdDialog
        .show({
          controller: 'SatBookReportController',
          templateUrl:
            '/static/assets/khapps/sat_book/includes/vm_dialog.html',
          locals: {
            selected_object_type: object_type,
            selected_object_slug: object_slug,
            growing_season: $rootScope.selected_growing_season.value
          },
          multiple: true,
          controllerAs: 'dl',
          parent: angular.element(document.body),
          skipHide: true
        })
        .then(
          function(response_data) {},
          function() {
            //pass
          }
        );
    };
    this.redirectPhoenixOperations = function() {
      var plot_id = self.plot.id;
      var plot_name = self.plot.name;
      PhoenixService.getRefreshToken().then((token) => {
        window.location = `${PhoenixService.getHostUrl}/farm/operation/?plot_id=${plot_id}&plot_name=${plot_name}&token=${token}`;
      });
    };
    this.share = function(slug) {
      $rootScope.shareObject('FieldPlotCrop', slug);
    };
    this.showCropbook = function(slug, name) {
      CropBookDialogService.show(slug, name);
    };
    this.onEditClick = function() {
      if (angular.isFunction(self.editClick)) {
        self.editClick();
      }
    };
    this.onSubscribeClick = function(plot) {
      if (angular.isFunction(self.subscribeClick)) {
        self.subscribeClick({ plot });
      }
    };
    this.onNotifyClick = function(slug, name) {
      if (angular.isFunction(self.notifyClick)) {
        self.notifyClick({ slug, name });
      }
    };
    this.onDeleteClick = function(plot) {
      DeletePlotService.checkDeletePlot(plot.id).subscribe( (deleted) => {
        this.plotDeleted();
      });
    };
    this.onShareClick = function(slug) {
      if (angular.isFunction(self.shareClick)) {
        self.shareClick({ slug });
      }
    };
  }
});
