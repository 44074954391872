/*
/*
    Handle the myfarm feature selection logic.

    Moved from static/khapps/generic/map/helpers/leaflet_map_functions.js and left largely unchanged, apart from changing
    from watching the rootScope selectedFeature object into an explicit function call.

    I've removed all references to $rootScope apart from page_id
    The main change is i've removed all reference to Trials code - this component is just for myfarm

    TODO shift sidebar to components - that will remove most (all?) of the $scope links
    TODO remove $rootScope dependencies. They all need to be abstracted into services and/or observables
*/
angular
  .module('FmsApp')
  .service('MyFarmMapFeatureSelectionService', function(
    $rootScope,
    $resource,
    UserSettingsService,
    WeatherUtilsService,
    ComponentBuilderService
  ) {
    function setActiveSidebar(component, scope) {
      ComponentBuilderService.attachTo('activeSidebar', component, scope);
    }
    function clearActiveSidebar(scope) {
      scope.sidebar_path = null;
      ComponentBuilderService.clear('activeSidebar');
    }

    this.selectionChanged = function(selectedLayer, scope) {
      scope.showSatelliteImages = false;
      if (typeof scope.sidebar !== 'undefined') {
        if (scope.sidebar.isVisible() === false) {
          scope.sidebar.toggle();
        }
      }
      var feature = (scope.feature = selectedLayer.feature);
      var properties = feature.properties;

      if (typeof selectedLayer.options.layer === 'undefined') {
        selectedLayer.options.layer = selectedLayer.options.title;
      }
      clearActiveSidebar(scope);
      switch (selectedLayer.options.layer) {
        case 'StoreLayer':
          if ($rootScope.page_id === 'store') scope.showEdit = true;
          setActiveSidebar('<store-sidebar-js></store-sidebar-js>', scope);
          break;
        case 'FarmLayer':
          setActiveSidebar('<farm-sidebar-js></farm-sidebar-js>', scope);
          break;
        case 'FieldLayer':
          if ($rootScope.page_id === 'farm') scope.showEdit = true;
          setActiveSidebar('<field-sidebar-js></field-sidebar-js>', scope);
          break;
        case 'FieldCropLayer':
          if ($rootScope.page_id === 'farm') scope.showEdit = true;
          setActiveSidebar('<plot-sidebar-js></plot-sidebar-js>', scope);
          break;
        case 'SoilSampleLayer':
          setActiveSidebar(
            '<soil-sample-sidebar-js></soil-sample-sidebar-js>',
            scope
          );
          break;
        default:
          if (properties.hasOwnProperty('logger_slug')) {
            if ($rootScope.page_id === 'weather') scope.showEdit = true;
            scope.sidebar_path =
              '/static/assets/khapps/weather/partials/mapSidebar/logger_sidebar.html';
          } else if (properties.hasOwnProperty('status')) {
            scope.parameterUINameConvertorMap = WeatherUtilsService.parameterUINameConvertorMap();
            scope.parameterUnitMap = WeatherUtilsService.parameterUnitMap();
            scope.userSettingDateFormat =
              UserSettingsService.userSettingDateFormat;
            scope.latestGatewayData = null;
            var apiQueryParams = {
              frequency: 'instantaneous',
              gateway_slug: String(properties.slug),
              node_slug: String(properties.nodes[0].slug),
              data_format: 'json',
              localize: '1'
            };
            $resource('/api/weather/sensor-data/').get(
              apiQueryParams,
              function(apiData) {
                if (apiData.data) {
                  scope.latestGatewayData = apiData.data.pop();
                }
                if ($rootScope.page_id === 'weather') scope.showEdit = true;
                scope.sidebar_path =
                  '/static/assets/khapps/weather/partials/mapSidebar/pws_sidebar.html';
              },
              function(error) {
                if ($rootScope.page_id === 'weather') scope.showEdit = true;
                scope.sidebar_path =
                  '/static/assets/khapps/weather/partials/mapSidebar/pws_sidebar.html';
              }
            );
          } else if (properties.hasOwnProperty('slug')) {
            scope.public_source_info = properties;
            scope.public_source_geometry = selectedLayer.feature.geometry;
            scope.latestEAData = selectedLayer.feature.latestReading;
            scope.pds_data = selectedLayer.feature;
            scope.pds_distance = selectedLayer.feature.distance;
            scope.sidebar_path =
              '/static/assets/khapps/weather/partials/mapSidebar/public_source_sidebar.html';
          }
      }
      scope.sidebar.show();
    };
  });
