angular.module('FmsApp')
.component('mapTableNavButtons', {
    templateUrl: '/static/assets/khapps/fms_app/myfarm/navbar/map-table-nav-buttons.component.html',
    controller: function($location, PhoenixService) {
        this.isTable = function() {
            return true;
        };
        this.PhoenixMap = function() {
            PhoenixService.getRefreshToken().then( (token)=> {
                window.location = `${PhoenixService.getHostUrl}farm/map?token=${token}`;
            });
        };
    }
});