angular.module('FmsApp').component('storeSidebar', {
  templateUrl:
    '/static/assets/khapps/fms_app/myfarm/map/sidebar/store/store-sidebar.component.html',
  bindings: {
    store: '<'
  },
  controller: function(StoreResource, StoreFactory, StoreEditDialogService) {
    const self = this;
    this.$onChanges = () => {
      StoreResource.get({ id: self.store.id }, function(store) {
        self.store = angular.merge({}, self.store, store);
      });
    };

    this.editStore = function(storeId) {
        StoreFactory.get({ store_id: storeId }, function(store) {
          store.longitude = store.geo_location.coordinates[0];
          store.latitude = store.geo_location.coordinates[1];
          delete store.geo_location;
          StoreEditDialogService.show(store);
        });
     };

  }
});
