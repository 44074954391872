(function (angular) {
    'use strict';
    angular.module('GeoDataPrintApp')
        .config(['$routeProvider', '$resourceProvider', '$locationProvider', GeoDataPrintConfig]);

    function GeoDataPrintConfig($routeProvider, $resourceProvider, $locationProvider) {
        $resourceProvider.defaults.stripTrailingSlashes = false;
    }

})(angular);
