angular.module('FmsApp').service("WaterSourceFormService", function () {

    this.waterSourceForm = function (scope) {
        return [{
            className: "flex-100",
            key: 'team_id',
            type: 'searchable_select',
            templateOptions: {
                label: "Team",
                required: true,
                options: scope.teams,
                labelProp: 'team_name',
                valueProp: 'id'
            }
        }, {
            className: "flex-50",
            key: 'source_name',
            type: 'input',
            templateOptions: {
                label: "Name",
                required: true
            }
        }, {
            className: "flex-50",
            key: 'type',
            type: 'searchable_select',
            templateOptions: {
                label: "Type",
                options: scope.water_source_types_dict
            }
        }, {
            className: "flex-100",
            id: 'farm_assets_textarea',
            key: 'description',
            type: 'textarea',
            templateOptions: {
                label: "Description",
                rows: 3
            }
        }];
    };
});