angular
  .module('FmsApp')
  .service('FarmEditDialogService', function(
    FarmFiltersService,
    MyFarmMapService
  ) {
    const self = this;

    this.updateFarmLayer = function(feature) {
      if (feature) {
        // update the filter bar
        const farm = feature.properties;
        FarmFiltersService.farmUpdated(farm.id, farm.name, farm.slug);
        // and the map
        MyFarmMapService.updateFarmFeature(feature);
        // select and zoom to it
        FarmFiltersService.selectFarm(farm.slug);
      }
      MyFarmMapService.removeDrawnLayer();
    };
  });
