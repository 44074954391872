/*
  FIXME this needs to become a component, but it's relying on lots of stuff still in the map scope.
  Move these into services and make this a component
 */
angular.module('FmsApp').directive('plotSidebarJs', function() {
  return {
    templateUrl:
      '/static/assets/khapps/fms_app/myfarm/map/sidebar/plot/plot-sidebar.directive.html',
    controller: function($scope, MyFarmMapService, FarmFiltersService) {
        $scope.plotDeleted = function() {
          MyFarmMapService.fullReload();
          FarmFiltersService.reload();
          $scope.sidebar.toggle();
      };
    }
  };
});
