angular.module('FmsApp').component('storeLotList', {
  templateUrl:
    '/static/assets/khapps/fms_app/myfarm/map/sidebar/store/store-lot-list.component.html',
  bindings: {
    storeSlug: '<'
  },
  controller: function($filter, $khMessage, StoreLotFactory) {
    const self = this;

    self.storeLots = [];

    this.$onChanges = function() {
      self.storeLots = [];
      if (self.storeSlug) {
        loadStoreLots(self.storeSlug);
      }
    };

    function loadStoreLots(storeSlug) {
      StoreLotFactory.query({ store_slug: storeSlug }).$promise.then(
        function(lots) {
          self.storeLots = lots;
        },
        function() {
          self.storeLots = [];
          $khMessage.show($filter('translate')('default_error_message'));
        }
      );
    }
  }
});
