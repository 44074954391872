// geodataprintapp.controller('GeoDataPrintController', ['$scope', '$rootScope', '$filter', '$khMessage', '$mdDialog',
//     'GeoDataPrintFactory', 'FarmFieldFactory', 'teams', 'fields', 'khmapprintserver',
//     function ($scope, $rootScope, $filter, $khMessage, $mdDialog, GeoDataPrintFactory, FarmFieldFactory, teams, fields, khmapprintserver) {
//         setUpForm();
//
//         function setUpForm() {
//             $scope.team_list = teams;
//             var list_of_fields = [];
//             angular.forEach(fields.features, function (value, key) {
//                 this.push({'name': value.properties.field_name, 'value': value.properties.field_slug});
//             }, list_of_fields);
//             $scope.field_list = list_of_fields;
//             $scope.geodata_print_options_form = load_geodata_print_options_formly_fields($scope);
//         }
//
//         function submit_map_print_request(GeoDataPrintFactory, map_print_json, $mdDialog, $khMessage) {
//             GeoDataPrintFactory.save(map_print_json, function (success_data) {
//                 templatePath = '';
//                 printDownloadURL = khmapprintserver + success_data.downloadURL
//                 html_content = '<a href='' + printDownloadURL + ''>Download</a>'
//
//                 var confirm = $mdDialog.confirm({skipHide: true})
//                     .title('Download URL')
//                     .htmlContent(html_content)
//                     .ariaLabel('close ');
//
//                 $mdDialog.show(confirm).then(function () {
//                     $mdDialog.hide(success_data);
//                 }, function () {
//                     $mdDialog.hide(success_data);
//
//                 });
//                 $mdDialog.hide()
//             }, function (error_data) {
//                 $khMessage.show('Failed to record your request to download GeoSpatial data please try again.', 50000);
//             });
//         }
//
//         function get_scale(dpi) {
//             var DOTS_PER_INCH = dpi;
//             var INCHES_PER_METER = 1.0 / 0.02540005080010160020;
//             var INCHES_PER_KM = INCHES_PER_METER * 1000.0;
//             var sw = MAP.getBounds().getSouthWest();
//             var ne = MAP.getBounds().getNorthEast();
//             var halflat = ( sw.lat + ne.lat ) / 2.0;
//             var midLeft = new L.LatLng(halflat, sw.lng);
//             var midRight = new L.LatLng(halflat, ne.lng);
//             var mwidth = midLeft.distanceTo(midRight);
//             var pxwidth = MAP.getSize().x;
//             var kmperpx = mwidth / pxwidth / 1000.0;
//             var scale = (kmperpx || 0.000001) * INCHES_PER_KM * DOTS_PER_INCH;
//             scale *= 2.0; // no idea why but it's doubled
//             scale = 250 * Math.round(scale / 250.0); // round to the nearest 1,000 so we can fit MapFish print's finite set of scales
//             return scale
//         }
//
//         $scope.geodata_print = function () {
//             var field_layer;
//             FarmFieldFactory.get({
//                 field_slug: $scope.geodata_print_options.field_slug,
//                 content_type: 'geojson'
//             }, function (success_data) {
//                 field_layer = success_data
//                 var map_print_json = {};
//                 // var map_center = [-92512.69, 6815378.64]
//                 var map_center = [field_layer.properties.field_longitude, field_layer.properties.field_latitude]
//                 var map_layers = []
//                 var map_layer = {}
//                 var map_table = {}
//                 map_print_json['layout'] = 'A4 landscape';
//                 map_print_json['outputFormat'] = 'pdf';
//                 map_print_json['attributes'] = {}
//                 map_print_json['attributes']['title'] = $scope.geodata_print_options.print_title;
//                 map_print_json['attributes']['subtitle'] = $scope.geodata_print_options.print_subtitle;
//                 map_print_json['attributes']['client_name'] = $scope.geodata_print_options.team_slug;
//                 map_print_json['attributes']['client_address'] = $scope.geodata_print_options.team_slug;
//                 map_print_json['attributes']['createdBy'] = $scope.geodata_print_options.field_slug;
//                 map_print_json['attributes']['map'] = {};
//                 map_print_json['attributes']['map']['projection'] = 'EPSG:3857';
//                 map_print_json['attributes']['map']['center'] = map_center;
//                 map_layers = [
//                     // {
//                     //     'geoJson': {
//                     //         'type': 'FeatureCollection',
//                     //         'name': 'Farm Field',
//                     //         'crs': {'type': 'name', 'properties': {'name': 'urn:ogc:def:crs:EPSG::3857'}},
//                     //         'features': [
//                     //             {
//                     //                 'type': 'Feature',
//                     //                 'properties': {},
//                     //                 'geometry': {
//                     //                     'type': 'Polygon',
//                     //                     'coordinates': [[[-92928.317137703023036, 6816420.094072898849845], [-93515.926792645332171, 6815550.62287615146488], [-93119.409708415987552, 6814939.126649872399867], [-91886.862627317517763, 6814628.601222465746105], [-91289.698343839583686, 6814829.248421707190573], [-92025.404741084392299, 6815674.833047113381326], [-92928.317137703023036, 6816420.094072898849845]]]
//                     //                 }
//                     //             }
//                     //         ]
//                     //     },
//                     //     'type': 'geojson',
//                     //     'style': {
//                     //         'version': '2',
//                     //         '*': {
//                     //             'symbolizers': [
//                     //                 {
//                     //                     'type': 'polygon',
//                     //                     'fillColor': '#FF0000',
//                     //                     'fillOpacity': 0,
//                     //                     'strokeColor': '#FFA829',
//                     //                     'strokeOpacity': 1,
//                     //                     'strokeWidth': 2
//                     //                 }
//                     //             ]
//                     //         }
//                     //     }
//                     // },
//                     {
//                         'geoJson': {
//                             'type': 'FeatureCollection',
//                             'name': 'Farm Field',
//                             'crs': {'type': 'name', 'properties': {'name': 'urn:ogc:def:crs:EPSG::3857'}},
//                             'features': [
//                                 {
//                                     'type': field_layer.type,
//                                     'properties': field_layer.properties,
//                                     'geometry': field_layer.geometry
//                                 }
//                             ]
//                         },
//                         'type': 'geojson',
//                         'style': {
//                             'version': '2',
//                             '*': {
//                                 'symbolizers': [
//                                     {
//                                         'type': 'polygon',
//                                         'fillColor': '#FFFF00',
//                                         'fillOpacity': 0,
//                                         'strokeColor': '#000000',
//                                         'strokeOpacity': 1,
//                                         'strokeWidth': 2
//                                     }
//                                 ]
//                             }
//                         }
//                     },
//                     {
//                         'type': 'osm',
//                         'baseURL': 'http://tile.openstreetmap.org',
//                         'imageExtension': 'png'
//                     }
//                 ];
//
//                 map_print_json['attributes']['map']['layers'] = map_layers;
//                 map_print_json['attributes']['map']['rotation'] = 0;
//                 map_print_json['attributes']['map']['dpi'] = 128;
//                 map_print_json['attributes']['map']['scale'] = 20000);
//                 map_print_json['attributes']['table'] = {};
//                 map_print_json['attributes']['table']['columns'] = ['name', 'value'];
//                 map_print_json['attributes']['table']['data'] = [
//                     ['Harvest Year', '2017'],
//                     ['Field Area', '20 ha.'],
//                     ['No of Trials', '34'],
//                     ['Trial Area', '15 ha'],
//                     ['Non Trial Area', '5 ha']
//                 ];
//                 map_print_json['attributes']['legend'] = {};
//                 map_print_json['attributes']['legend']['classes'] = [{
//                     'name': 'Legend:',
//                     'icons': ['http://localhost:8080/geoserver/wms?REQUEST=GetLegendGraphic&VERSION=1.0.0&FORMAT=image/png&WIDTH=20&HEIGHT=20&LAYER=topp:states']
//                 }];
//
//                 submit_map_print_request(GeoDataPrintFactory, map_print_json, $mdDialog, $khMessage);
//
//             }
//
//     }]);
//

(function (angular) {
    'use strict';
    angular.module('GeoDataPrintApp')
        .controller('GeoDataPrintController', GeoDataPrintController);

    GeoDataPrintController.$inject = ['$http', '$scope', '$rootScope', '$filter', '$khMessage', '$mdDialog', 'GeoDataPrintResource', 'GeoDataPrintFarmFieldResource', 'teams', 'fields', 'khmapprintserver'];

    function GeoDataPrintController($http, $scope, $rootScope, $filter, $khMessage, $mdDialog, GeoDataPrintResource, GeoDataPrintFarmFieldResource, teams, fields, khmapprintserver) {
        setUpForm();

        function setUpForm() {
            $scope.team_list = teams;
            var list_of_fields = [];
            angular.forEach(fields.features, function (value, key) {
                this.push({'name': value.properties.field_name, 'value': value.properties.field_slug});
            }, list_of_fields);
            $scope.field_list = list_of_fields;
            $scope.geodata_print_options_form = load_geodata_print_options_formly_fields($scope);
        }

        function submit_map_print_request(GeoDataPrintFarmFieldResource, map_print_json, $mdDialog, $khMessage) {
            GeoDataPrintResource.save(map_print_json, function (success_data) {
                var templatePath = '';
                var printDownloadURL = khmapprintserver + success_data.downloadURL;
                printDownloadURL = 'https://kisansatgeouat.kisanhub.com/mapfish/print/trials/report.pdf';
                var html_content = '<a href="' + printDownloadURL + '">Download</a>';
                var confirm = $mdDialog.confirm({skipHide: true, multiple: true})
                    .title('Download URL')
                    .htmlContent(html_content)
                    .ariaLabel('close ');
                $mdDialog.show(confirm).then(function () {
                    $mdDialog.hide(success_data);
                }, function () {
                    $mdDialog.hide(success_data);
                });
                $mdDialog.hide();
            }, function (error_data) {
                $khMessage.show('Failed to record your request to download GeoSpatial data please try again.', 50000);
            });
        }

        function get_scale(dpi) {
            var DOTS_PER_INCH = dpi;
            var INCHES_PER_METER = 1.0 / 0.02540005080010160020;
            var INCHES_PER_KM = INCHES_PER_METER * 1000.0;
            var sw = MAP.getBounds().getSouthWest();
            var ne = MAP.getBounds().getNorthEast();
            var halflat = (sw.lat + ne.lat) / 2.0;
            var midLeft = new L.LatLng(halflat, sw.lng);
            var midRight = new L.LatLng(halflat, ne.lng);
            var mwidth = midLeft.distanceTo(midRight);
            var pxwidth = MAP.getSize().x;
            var kmperpx = mwidth / pxwidth / 1000.0;
            var scale = (kmperpx || 0.000001) * INCHES_PER_KM * DOTS_PER_INCH;
            scale *= 2.0; // no idea why but it's doubled
            scale = 250 * Math.round(scale / 250.0); // round to the nearest 1,000 so we can fit MapFish print's finite set of scales
            return scale
        }

        $scope.geodata_print = function () {
            var field_layer;
            GeoDataPrintFarmFieldResource.get({
                field_slug: $scope.geodata_print_options.field_slug,
                crs: 3857,
                content_type: 'geojson'
            }, function (success_data) {
                field_layer = success_data;
                var map_print_json = {};
                var map_center = [field_layer.properties.field_longitude, field_layer.properties.field_latitude];
                var map_layers = [];
                var map_layer = {};
                var map_table = {};
                map_print_json['layout'] = 'A4 landscape';
                map_print_json['outputFormat'] = 'pdf';
                map_print_json['attributes'] = {};
                map_print_json['attributes']['title'] = $scope.geodata_print_options.print_title;
                map_print_json['attributes']['subtitle'] = $scope.geodata_print_options.print_subtitle;
                map_print_json['attributes']['client_name'] = $scope.geodata_print_options.team_slug;
                map_print_json['attributes']['client_address'] = $scope.geodata_print_options.team_slug;
                map_print_json['attributes']['createdBy'] = $scope.geodata_print_options.field_slug;
                map_print_json['attributes']['map'] = {};
                map_print_json['attributes']['map']['projection'] = 'EPSG:3857';
                map_print_json['attributes']['map']['center'] = map_center;
                map_layers = [
                    {
                        'geoJson': {
                            'type': 'FeatureCollection',
                            'name': 'Farm Field',
                            'crs': {'type': 'name', 'properties': {'name': 'urn:ogc:def:crs:EPSG::3857'}},
                            'features': [
                                {
                                    'type': field_layer.type,
                                    'properties': field_layer.properties,
                                    'geometry': field_layer.geometry
                                }
                            ]
                        },
                        'type': 'geojson',
                        'style': {
                            'version': '2',
                            '*': {
                                'symbolizers': [
                                    {
                                        'type': 'polygon',
                                        'fillColor': '#FFFF00',
                                        'fillOpacity': 0,
                                        'strokeColor': '#000000',
                                        'strokeOpacity': 1,
                                        'strokeWidth': 2
                                    }
                                ]
                            }
                        }
                    },
                    {
                        'type': 'osm',
                        'baseURL': 'http://tile.openstreetmap.org',
                        'imageExtension': 'png'
                    }
                ];

                map_print_json['attributes']['map']['layers'] = map_layers;
                map_print_json['attributes']['map']['rotation'] = 0;
                map_print_json['attributes']['map']['dpi'] = 128;
                map_print_json['attributes']['map']['scale'] = $scope.geodata_print_options.print_scale;
                map_print_json['attributes']['table'] = {};
                map_print_json['attributes']['table']['columns'] = [];
                map_print_json['attributes']['table']['data'] = [];
                // map_print_json['attributes']['table']['columns'] = ['name', 'value'];
                // map_print_json['attributes']['table']['data'] = [
                //     ['Harvest Year', '2017'],
                //     ['Field Area', '20 ha.'],
                //     ['No of Trials', '34'],
                //     ['Trial Area', '15 ha'],
                //     ['Non Trial Area', '5 ha']
                // ];
                // map_print_json['attributes']['legend'] = {};
                // map_print_json['attributes']['legend']['classes'] = [{
                //     'name': 'Legend:',
                //     'icons': ['http://localhost:8080/geoserver/wms?REQUEST=GetLegendGraphic&VERSION=1.0.0&FORMAT=image/png&WIDTH=20&HEIGHT=20&LAYER=topp:states']
                // }];
                $http({
                    method: 'post',
                    url: 'https://kisansatgeouat.kisanhub.com/mapfish/print/trials/buildreport.pdf',
                    data: map_print_json,
                    responseType: 'arraybuffer',
                    headers: {accept: 'application/pdf'},
                    cache: true,
                }).success(function (response, status, headers) {
                    var file = new Blob([response], {type: 'application/pdf'});
                    var fileURL = URL.createObjectURL(file);
                    window.open(fileURL);
                    // $mdDialog.hide();
                });

            });
        };

        function load_geodata_print_options_formly_fields($scope) {
            return [
                // {
                //     className: 'flex-100',
                //     type: 'searchable_select',
                //     key: 'team_slug',
                //     id: 'team_id',
                //     templateOptions: {
                //         required: true,
                //         multiple: false,
                //         label: 'Select Team',
                //         placeholder: 'Select team',
                //         valueProp: 'slug',
                //         labelProp: 'name',
                //         options: $scope.team_list
                //     }
                // },
                {
                    className: 'flex-100',
                    type: 'searchable_select',
                    key: 'print_layout_template',
                    id: 'team_id',
                    templateOptions: {
                        required: true,
                        multiple: false,
                        label: 'Select Layout Template',
                        placeholder: 'Select layout template',
                        valueProp: 'value',
                        labelProp: 'name',
                        options: [
                            {name: 'Farm Map', value: 'FARM_MAP'},
                            {name: 'Field Map', value: 'FIELD_MAP'},
                            {name: 'Crop Map', value: 'CROP_MAP'},
                            {name: 'Trial Field', value: 'TRIAL_FIELD'},
                            {name: 'Trial Plot', value: 'TRIAL_PLOT'}
                        ]
                    }
                },
                {
                    className: 'flex-100',
                    type: 'searchable_select',
                    key: 'field_slug',
                    id: 'team_id',
                    templateOptions: {
                        required: true,
                        multiple: false,
                        label: 'Select Field',
                        placeholder: 'Select field',
                        valueProp: 'value',
                        labelProp: 'name',
                        options: $scope.field_list
                    }
                },
                {
                    className: 'flex-100',
                    type: 'input',
                    key: 'print_title',
                    templateOptions: {
                        required: true,
                        label: 'Map Title',
                        placeholder: 'Map title',
                    }
                },
                {
                    className: 'flex-100',
                    type: 'input',
                    key: 'print_subtitle',
                    templateOptions: {
                        required: true,
                        label: 'Map Sub-Title',
                        placeholder: 'Map sub-title',
                    }
                },
                {
                    className: 'flex-100',
                    type: 'searchable_select',
                    key: 'print_scale',
                    id: 'team_id',
                    templateOptions: {
                        required: true,
                        multiple: false,
                        label: 'Map Scale',
                        placeholder: 'Map scale',
                        valueProp: 'value',
                        labelProp: 'name',
                        options: [{'name': '1:100', 'value': 100}, {'name': '1:500', 'value': 500}, {
                            'name': '1:1000',
                            'value': 1000
                        }, {'name': '1:5000', 'value': 5000}, {'name': '1:10000', 'value': 10000}, {
                            'name': '1:25000',
                            'value': 25000
                        },]
                    }
                },
            ];
        };
    }

})(angular);
