angular.module('FmsApp').factory('FieldResource', function($resource) {
  return $resource(
    '/api/field/:id',
    { id: '@id' },
    {
      permission: {
        method: 'GET',
        url: '/api/field/:id/permission'
      }
    }
  );
});
