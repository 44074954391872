angular.module('FmsApp').component('plantVarietyFilter', {
    templateUrl: '/static/assets/khapps/fms_app/forms/filters/plant-variety-filter.component.html',
    bindings: {
        onChange: '<'
    },
    controller: function (PlantVarietyListFactory) {
        this.varieties = PlantVarietyListFactory.query({'crop_name': 'Potato'});
        this.variety = "All Varieties";
    }
});
