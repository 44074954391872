angular.module('FmsApp').service('ComponentBuilderService', function($compile) {
  this.attachTo = function(containerId, componentTemplate, scope) {
    const $component = $compile(componentTemplate)(scope);

    const $element = angular.element(`#${containerId}`);
    $element.empty();
    $element.append($component);
  };

  this.clear = function(containerId) {
    const $element = angular.element(`#${containerId}`);
    $element.empty();
  };
});
