angular.module('FmsApp').service('DateResponseTransformer', function($http) {
    function appendTransform(defaults, transform) {
      // We can't guarantee that the default transformation is an array
      defaults = angular.isArray(defaults) ? defaults : [defaults];

      // Append the new transformation to the defaults
      return defaults.concat(transform);
    }

    function convertDates(obj, key) {
        if (angular.isArray(obj)) {
            return _.map(obj, convertDates);
        } else if (angular.isObject(obj)) {
            return _.mapObject(obj, convertDates);
        } else {
            if (angular.isString(key) && (key.indexOf('_date')>=0 || key.indexOf('date_')>=0)) {
                return new Date(obj);
            }
        }
        return obj;
    }

    this.getTransformer = function() {
        return appendTransform($http.defaults.transformResponse, function(value) {
            return convertDates(value);
        });
    }
})
