angular.module('FmsApp').component('soilReportSummary', {
    templateUrl: '/static/assets/khapps/fms_app/myfarm/form/soil/report/soil-report-summary.component.html',
    bindings: {
        sampleId: '<'
    }, 
    controller: function($mdDialog, SoilReportDialogService,
                         FarmFieldResource, SoilSampleResource, SoilReportResource, KisanHubIconsResource) {
        
        this.khIcon = KisanHubIconsResource;
        this.$onChanges = () => {
            this.sample = SoilSampleResource.get({id: this.sampleId});
            SoilReportResource.get({sample_id: this.sampleId}).$promise.then( (reports) => {
               this.reports = reports.results; 
            });
        };

        this.viewDetail = (report) => {
            $mdDialog.show({
                controller: function() {
                    this.edit = () => {
                        SoilReportDialogService.show(report);
                    };
                    this.cancel = () => {
                       $mdDialog.cancel();
                    }  
                },
                controllerAs: '$ctrl',
                bindToController: true,
                templateUrl: '/static/assets/khapps/fms_app/myfarm/form/soil/report/soil-report-detail-dialog.html',
                locals: {
                    report: report
                }
            });
        }
        
        
    }
});
