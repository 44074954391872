import {downgradeComponent, downgradeInjectable} from '@angular/upgrade/static';
import {AddVarietyService} from '../../angular/src/app/farm/variety/add-variety.service';
import {VarietyService} from '../../angular/src/app/shared/core/services/variety.service';
import {SoilReportComponent} from '../../angular/src/app/soil-report/soil-report.component';
import {SoilReportUpdateService} from '../../angular/src/app/soil-report/soil-report-update.service';
import {FieldInventoryComponent} from '../../angular/src/app/farm/field-inventory/field-inventory/field-inventory/field-inventory.component';

import {FarmSidebarComponent} from '../../angular/src/app/farm/map/farm-sidebar/farm-sidebar.component';
import {FarmDialogService, PlotDialogService} from '../../angular/src/app/farm/map';
import {DeleteFieldService} from "../../angular/src/app/farm/delete-field.service";
import {DeletePlotService} from "../../angular/src/app/farm/delete-plot.service";
import {FieldDialogService} from "../../angular/src/app/farm/map/common/services/field-dialog.service";

require('angulartics');

export default angular
  .module('FmsApp', [
    'angulartics',
    require('angulartics-google-analytics'),
    'KhBase',
    'KhChart',
    'FiltersData',
    'leaflet-directive',
    'ngTableToCsv',
    'highcharts-ng',
    'ShareWithApp',
    'CropBookApp',
    'GeoDataExportApp',
    'GeoDataPrintApp',
    'KisanSatApp',
    'SatBookApp',
    'KisanAuthApp',
    'WeatherComponents',
    'user_notification_preferences',
    'ApiUtils.ksat',
    'khUtils',
    'ApiUtils.khutils.ui',
    'ApiUtils.utilities',
    'ApiUtils.dms.soilType',
    'ApiUtils.utilities',
    'fixed.table.header',
    'ngMessages',
    'cp.ng.fix-image-orientation'

    // FIXME (webpack) move these to common module
  ])
  .filter('html', function($sce) {
    return function(val) {
      return $sce.trustAsHtml(val);
    };
  })
  .filter('dateToString', function() {
    return function(value) {
      return moment(value, 'YYYY-MM-DDTHH:mm:ss').format('DD MMM YYYY');
    };
  });

angular
  .module('FmsApp')
  .directive(
    'appFieldInventory',
    downgradeComponent({
      component: FieldInventoryComponent,
      inputs: ['farmFilter', 'selectedPlotId'],
      outputs: ['itemClick']
    })
  )
  .service('AddVarietyService', downgradeInjectable(AddVarietyService))
  .service('VarietyService', downgradeInjectable(VarietyService))
  .service(
    'SoilReportUpdateService',
    downgradeInjectable(SoilReportUpdateService)
  )
  .directive(
    'soilReportTable',
    downgradeComponent({ component: SoilReportComponent })
  )
  .directive(
    'appFarmSidebar',
    downgradeComponent({
      component: FarmSidebarComponent,
      inputs: ['farmId'],
      outputs: ['itemChanged', 'notificationClick', 'deleteClick']
    })
  )
  .service('FarmDialogService', downgradeInjectable(FarmDialogService))
  .service('PlotDialogService', downgradeInjectable(PlotDialogService))
  .service('FieldDialogService', downgradeInjectable(FieldDialogService))
    .service(
        'DeleteFieldService',
        downgradeInjectable(DeleteFieldService)
    )
    .service(
        'DeletePlotService',
        downgradeInjectable(DeletePlotService)
    );
