angular.module('FmsApp').service("FMSGenericHelperService", function ($rootScope) {

    this.getFilterDict = function ($filter, key, site_code) {
        var dict = {};
        dict[site_code] = {
            id: 'text',
            placeholder: $filter('translate')('common.filterBy') + $filter('translate')(key + '.' + site_code + '').toLowerCase()
        };
        return dict;
    };

    this.appSideBar = function (app_pageid, subapp_pageid) {
        $rootScope.page_id = app_pageid;
        if (subapp_pageid) $rootScope.sub_app_page_id = subapp_pageid;
        else delete $rootScope.sub_app_page_id;
    };

    this.initController = function ($scope, $rootScope, $filter) {
        $rootScope.callFunction = function (name) {
            if (angular.isFunction($scope[name]))
                $scope[name]();
        };
        $rootScope.getSort = function (sort_text) {
            return $filter('translate')('common.sortBy') + sort_text
        };
        $rootScope.getColumnFullName = function (sort_text) {
            return $filter('translate')(sort_text)
        };
        $rootScope.field_inventory_column_config = {};
        $rootScope.field_inventory_column_config.column_team = true;
        $rootScope.field_inventory_column_config.column_farm = true;
        $rootScope.field_inventory_column_config.column_field = true;
        $rootScope.field_inventory_column_config.column_plot = true;
        $rootScope.field_inventory_column_config.column_crop = true;
        $rootScope.field_inventory_column_config.column_variety = true;
        $rootScope.field_inventory_column_config.column_location = true;
        $rootScope.field_inventory_column_config.column_cropped_area = true;
        $rootScope.field_inventory_column_config.column_expected_planting_date = true;
        $rootScope.field_inventory_column_config.column_actual_planting_date = true;
        $rootScope.field_inventory_column_config.column_expected_harvest_date = true;
        $rootScope.field_inventory_column_config.column_expected_harvest_week = true;
        $rootScope.field_inventory_column_config.column_actual_harvest_date = true;
        $rootScope.field_inventory_column_config.column_estimated_yield = true;
        $rootScope.field_inventory_column_config.column_total_tonnage = true;
        $rootScope.field_inventory_column_config.column_market = true;
        $rootScope.field_inventory_column_config.column_customer = true;
        $rootScope.field_inventory_column_config.column_alert_status = true;
        $rootScope.field_inventory_column_config.column_harvest_complete = true;

    };

    this.coordinatesToString = function (latlngs) {
        var coordinates = [];
        for (var i = 0; i < latlngs.length; i++) {
            var ilng = latlngs[i].lng;
            var ilat = latlngs[i].lat;
            coordinates.push([ilng, ilat])
        }
        coordinates.push([latlngs[0].lng, latlngs[0].lat]);
        return coordinates.toString();
    };
});


