angular.module('FmsApp').component('fieldInventoryWithSummary', {
  templateUrl:
    '/static/assets/khapps/fms_app/myfarm/field_inventory/field-inventory-with-summary.component.html',
  controller: function(
    $routeParams,
    CropBookDialogService,
    FarmFiltersService,
    FMSGenericHelperService
  ) {
    const self = this;

    self.farmFilter = null;
    self.selectedPlotId = $routeParams.plotSlug;
    self.isClientIndia = null;
    self.isLoading = false;

    self.$onInit = function() {
      self.isLoading = true;
      FarmFiltersService.get$().subscribe(filter => {
        self.farmFilter = filter;
        self.isLoading = false;
        });
      FMSGenericHelperService.appSideBar('farm', null);
    };

    self.showCropBook = function(item) {
      CropBookDialogService.show(item.plot_slug, item.plot_name);
    };
  }
});
