/**
 * Simple service that caches the geojson data based on growing season and team slug
 */
angular.module('FmsApp').service("MyFarmCachingMapDataService", function($resource, $q, $khMessage, $timeout) {

    var cached;
    var lastSeason;
    var lastTeamSlug;
    var self=this;
    var single_crop_resource = $resource('/api/fms/geo/field_crop/:slug');
    let genuses = [];

    this.getGenusColourCode = function(genusId) {
        const genus = genuses.find(genus => genus.id === genusId);
        if (!genus) {
            return null;
        }

        return genus.colour_code;
    };

    /**
     * Reload the map, using the previous cached value for the given season and team slug
     * @param team_slug - team slug
     * @param growing_season - growing season
     * @param ignoreCache - if true then force a reload and ignore the cache
     * @return promise for data
     */
    this.reload = function(team_slug, growing_season, ignoreCache, teamId) {
        var defer = $q.defer();
        if (!ignoreCache && self.isCached(team_slug, growing_season)) {
            // we need to do this in a timeout to let leaflet initialise itself
            $timeout(function() {
                defer.resolve(cached);
            });
        } else {
          $resource('/api/genus/').query().$promise.then(function(items){
                genuses = items;

                return $q.all([
                    $resource('/api/farm/geojson/').get({
                        team_id: teamId,
                        season: growing_season
                    }).$promise,
                    $resource('/api/field/geojson/').get({
                        team_id: teamId,
                        season: growing_season
                    }).$promise,
                    $resource('/api/soil/sample/geojson/').get({
                        team_id: teamId,
                        season: growing_season
                    }).$promise,
                    $resource('/api/plot/geojson/').get({
                        team_id: teamId,
                        season: growing_season
                    }).$promise,
                    $resource('/api/store/geojson/').get({
                        team_id: teamId,
                        season: growing_season
                    }).$promise
                ]);
            }).then(function(responses){
                const result = {
                    farm: responses[0],
                    field: responses[1],
                    soil_sample: responses[2],
                    fieldcrop: responses[3],
                    store: responses[4]
                };

                lastSeason = growing_season;
                lastTeamSlug = team_slug;
                cached = result;
                defer.resolve(result);
            }).catch(function(){
                $khMessage.show("Failed to fetch map data");
                defer.reject();
            })
        }
        return defer.promise;
    };

    /**
     * Have we cached this data?
     * @param team_slug
     * @param growing_season
     * @returns {*|boolean}
     */
    this.isCached = function(team_slug, growing_season) {
        return (cached && lastSeason && lastSeason === growing_season && lastTeamSlug && lastTeamSlug===team_slug);
    };

    /**
     * Fetch a single field crop - this will clear the cache
     * @param slug - slug
     * @returns promise that resolved to the field crop as geojson
     */
    this.getSingleCrop = function(slug) {
        cached = null;
        return single_crop_resource.get({slug:slug}).$promise;
    }

});