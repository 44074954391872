angular.module('FmsApp').service("FileUploadFormService", function () {

    this.importFieldUAVForm = function
        ($scope, $rootScope) {
        return [{
            className: "flex-100",
            type: "input",
            key: "uploaded_filename",
            templateOptions: {
                disabled: true,
                required: true,
                label: "Uploaded File",
                placeholder: "Uploaded FileName"

            }
        }, {
            className: "flex-100",
            type: "input",
            key: "resolution",
            templateOptions: {
                required: true,
                label: "Display Name",
                placeholder: "Display name"

            }
        }, {
            className: "flex-50",
            type: "input",
            //type: "searchable_select",
            key: "selected_team_name",
            //defaultValue: $rootScope.selected_team !== "All Teams" ? $rootScope.teams.filter(function (team) {
            //        return team.slug === $rootScope.selected_team;
            //    })[0].value : null,
            templateOptions: {
                disabled: $rootScope.selected_team !== "All Teams",
                required: true,
                label: "Select Team",
                placeholder: "Select team",
                options: $rootScope.teams,
            }
        }, {
            className: "flex-50",
            "type": "searchable_select",
            "key": "selected_field",
            templateOptions: {
                optionsAttr: 'bs-options',
                ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                valueProp: 'value',
                labelProp: 'name',
                placeholder: "Select field",
                label: "Select Field",
                required: true,
                options: $scope.fields
            }
        }, {
            className: "flex-100",
            "type": "searchable_select",
            "key": "processing_type",
            templateOptions: {
                optionsAttr: 'bs-options',
                ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                valueProp: 'value',
                labelProp: 'name',
                placeholder: "Select image category",
                label: "Image Category",
                required: true,
                options: [
                    {name: "Aerial Image (RGB)", value: "RGB"},
                    {name: "Crop Height (DEM_Height)", value: "DEM_HEIGHT"},
                    {name: "Digital Elevation Model (DEM)", value: "UAV_DEM"},
                    {name: "Green Difference Vegetation Index (GDVI)", value: "GDVI"},
                    {name: "Leaf Area Index (LAI)", value: "LAI"},
                    {name: "Multispectral Image (MS-RGB)", value: "UAV_MS"},
                    {name: "Normalized Difference Red Edge Index (NDRE)", value: "NDRE"},
                    {name: "Normalized Difference Vegetation Index (NDVI)", value: "NDVI"},
                    {name: "Optimized Soil Adjusted Vegetation Index (OSAVI)", value: "OSAVI"}
                ]
            }
        }, {
            className: "flex-50",
            "type": "searchable_select",
            "key": "growing_season",
            templateOptions: {
                optionsAttr: 'bs-options',
                ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                valueProp: 'value',
                labelProp: 'name',
                placeholder: "Select growing season",
                label: "Image Growing Season",
                required: true,
                options: $scope.planting_season_list
            }
        }, {
            className: "flex-50",
            type: "datepicker",
            key: "readable_acquisition_date",
            defaultValue: new Date(),
            templateOptions: {
                required: true,
                format: "DD MMMM YYYY",
                label: "Image Acquisition Date",
                placeholder: "Enter date"
            }
        }];
    };

    this.importFieldOperationForm = function ($scope, $rootScope) {
        return [{
            className: "flex-100",
            "type": "input",
            "key": "field_name",
            id: "import_field_name",
            templateOptions: {
                label: "Field",
                disabled: true
            }
        }, {
            className: "flex-100",
            type: "searchable_select",
            "key": "planting_season",
            "id": "import_planting_season",
            templateOptions: {
                label: "Planting Season",
                required: true,
                options: $rootScope.planting_season_list
            }
        }
        ];
    };

    this.importFieldBoundaryForm = function ($scope) {
    return [{
        className: "flex-100",
        "type": "searchable_select",
        "key": "farm",
        templateOptions: {
            optionsAttr: 'bs-options',
            ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
            valueProp: 'value',
            labelProp: 'name',
            placeholder: "Select farm",
            label: "Farm",
            required: true,
            options: $scope.farmChoices
        }
    }, {
        className: "flex-100",
        "type": "searchable_select",
        "key": "column_name",
        templateOptions: {
            optionsAttr: 'bs-options',
            ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
            valueProp: 'value',
            labelProp: 'name',
            placeholder: "Select column name",
            label: "Column Containing Field Name",
            required: true,
            options: $scope.constantChoices
        }
    }, {
        className: "flex-100",
        "type": "searchable_select",
        "key": "soil_type",
        templateOptions: {
            optionsAttr: 'bs-options',
            ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
            valueProp: 'value',
            labelProp: 'name',
            placeholder: "Select soil type",
            label: "Representative Soil Type",
            required: true,
            options: $scope.soilChoices
        }
    }];
};

    this.FileUploadForm = function (constants) {
    return [{
        className: "flex-100",
        "type": "searchable_select",
        "key": "data_category",
        templateOptions: {
            optionsAttr: 'bs-options',
            ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
            valueProp: 'value',
            labelProp: 'name',
            placeholder: "Select data category",
            label: "Data Category",
            required: true,
            options: constants
        }
    }, {
        className: "flex-100",
        "type": "searchable_select",
        "key": "data_provider",
        templateOptions: {
            optionsAttr: 'bs-options',
            ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
            valueProp: 'value',
            labelProp: 'name',
            placeholder: "Select data provider",
            label: "Data Provider",
            required: true,
            options: []
        },
        controller: function ($scope, FileUploadProvidersFactory) {
            $scope.$watch("$parent.model.data_category", function handleChange(newVal, oldVal) {
                if (newVal) {
                    $scope.$parent.model.data_provider = null;
                    $scope.$parent.model.data_type = null;
                    FileUploadProvidersFactory.query({category: newVal}, function (result) {
                        $scope.to.options = result;
                    });
                }
            });
        }
    }, {
        className: "flex-100",
        "type": "searchable_select",
        "key": "data_type",
        templateOptions: {
            optionsAttr: 'bs-options',
            ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
            valueProp: 'value',
            labelProp: 'name',
            placeholder: "Select data type",
            label: "Data Type",
            required: true,
            options: [],
            type: 'file_upload'
        },
        controller: function ($scope, FileUploadDataTypesFactory) {
            $scope.$watch("$parent.model.data_provider", function handleChange(newVal, oldVal) {
                if (newVal) {
                    $scope.$parent.model.data_type = null;
                    FileUploadDataTypesFactory.query({
                        category: $scope.$parent.model.data_category,
                        provider: newVal
                    }, function (result) {
                        $scope.to.options = result;
                    });
                }
            });
        }
    }];
};
});
