angular.module('FmsApp').service("OperationsService", function () {
    var self=this;

    var subject$ = new Rx.Subject();

    /**
     * Return a subject that can be used to subscribe to operations updates
     * @return {Rx.Subject}
     */
    self.get$ = function() {
        return subject$;
    };
});