angular.module('FmsApp').service("GeoUtilsService", function ($q) {

    var self = this;

    function convertGoogleLocations(address_components) {
        var address = {
            "address_line1": null,
            "address_line2": null,
            "region": null,
            "sub_region": null,
            "sub_sub_region": null,
            "location": null,
            "post_code": null,
            "country": null
        };

        for (var i = 0; i < address_components.length; i++) {
            for (var b = 0; b < address_components[i].types.length; b++) {
                if (address_components[i].types[b] == "street_number") {
                    address.address_line1 = address_components[i].long_name;
                    break;
                }
                if (address_components[i].types[b] == "route") {
                    address.address_line2 = address_components[i].long_name;
                    if (address.address_line1 == null) {
                        address.address_line1 = address.address_line2;
                    }
                    break;
                }
                if (address_components[i].types[b] == "locality") {
                    address.location = address_components[i].long_name;
                    break;
                }
                if (address_components[i].types[b] == "postal_town") {
                    address.sub_sub_region = address_components[i].long_name;
                    if (address.location == null) {
                        address.location = address.sub_sub_region;
                    }
                    break;
                }
                if (address_components[i].types[b] == "administrative_area_level_2") {
                    address.sub_region = address_components[i].long_name;
                    break;
                }
                if (address_components[i].types[b] == "administrative_area_level_1") {
                    address.region = address_components[i].long_name;
                    if (address.sub_region == null) {
                        address.sub_region = address.region;
                    }
                    break;
                }
                if (address_components[i].types[b] == "postal_code") {
                    address.post_code = address_components[i].long_name;
                    break;
                }
                if (address_components[i].types[b] == "country") {
                    address.country = address_components[i].short_name;
                    break;
                }
            }
        }
        return address;
    }

    this.isAreaValid = function (area) {
        return !(area >= 999.9999);
    };

    // serialise an array of coordinaties
    this.serialiseLatLngArray = function (latlngs) {
        return _.map(latlngs, function (latlng) {
            return [latlng[0], latlng[1]]
        }).toString();
    };

    this.getBoundaryFromFeature = function (feature) {
        const actual_area = L.GeometryUtil.geodesicArea(feature.getLatLngs());
        return {
            bounds: feature.toGeoJSON().geometry.coordinates[0],
            area: Number(((actual_area) / 10000).toFixed(4))
        }
    };

    this.coordinatesToString = function (latlngs) {
        var coordinates = [];
        for (var i = 0; i < latlngs.length; i++) {
            var ilng = latlngs[i].lng;
            var ilat = latlngs[i].lat;
            coordinates.push([ilng, ilat]);
        }
        coordinates.push([latlngs[0].lng, latlngs[0].lat]);
        return coordinates.toString();
    }

    this.getCentroidLng = function (latlngs, precision) {
        var divisor = latlngs.length;
        var lng = 0;
        for (var i = 0; i < latlngs.length; i++) {
            lng += latlngs[i].lng;
        }
        return (lng / divisor).toFixed(precision)
    };

    this.getCentroidLat = function (latlngs, precision) {
        var divisor = latlngs.length;
        var lat = 0;
        for (var i = 0; i < latlngs.length; i++) {
            lat += latlngs[i].lat;
        }
        return (lat / divisor).toFixed(precision)
    };

    this.getMarkerGeocode = function (layer) {
        var geocoder = new google.maps.Geocoder();
        var defer = $q.defer();
        geocoder.geocode({'location': {lat: layer._latlng.lat, lng: layer._latlng.lng}}, function (results, status) {
            var geocoder = {};
            if (status === google.maps.GeocoderStatus.OK) {
                if (results[0]) {
                    var address = convertGoogleLocations(results[0].address_components);
                    if (address.region == null) {
                        address.region = address.sub_region;
                    }
                    if (address.location == null) {
                        address.location = address.sub_region;
                    }
                    if (address.location == null) {
                        address.location = address.region;
                    }

                    geocoder.location = address.location;
                    geocoder.address_line1 = address.address_line1;
                    geocoder.address_line2 = address.address_line2;
                    geocoder.sub_sub_region = address.sub_sub_region;
                    geocoder.sub_region = address.sub_region;
                    geocoder.region = address.region;
                    geocoder.post_code = address.post_code;
                    geocoder.country = address.country;

                }
            }
            geocoder.latitude = Number((layer._latlng.lat).toFixed(8));
            geocoder.longitude = Number((layer._latlng.lng).toFixed(8));
            defer.resolve(geocoder);
        });
        return defer.promise;
    }

});
