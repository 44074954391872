
angular.module('FmsApp').directive('fieldSidebarJs', function() {
  return {
    templateUrl:
      '/static/assets/khapps/fms_app/myfarm/map/sidebar/field/field-sidebar.directive.html',
    controller: function(
      $mdDialog,
      $filter,
      FarmFieldFactory,
      MyFarmMapService,
      FarmFiltersService,
      $scope
    ) {
      $scope.fieldDeleted = function() {
          MyFarmMapService.fullReload();
          FarmFiltersService.reload();
          $scope.sidebar.toggle();
      };
    }
  };
});
