angular.module('FmsApp').service("SoilSampleEditDialogService", function ($mdDialog, $khMessage,
                                                        MyFarmMapService, FarmConstantsService,
                                                        ErrorLoggingService,
                                                        SoilSampleResource, $q) {
    
    function controller()
    {
        this.form_fields = [
            {
                className: "flex-100",
                type: "input",
                key: "name",
                id: "soil_sample_name",
                templateOptions: {
                    label: "Sample Label",
                    required: true,
                    placeholder: "Sample label",
                    maxlength: 25
                }
            },
            {
                className: "flex-50",
                type: "datepicker",
                key: "date",
                defaultValue: new Date(),
                templateOptions: {
                    label: "Sampling Date",
                    required: true,
                    placeholder: "dd/mm/yyyy"

                }
            },
            {
                className: "flex-50",
                type: "input",
                key: "depth",
                id: "soil_sample_depth_value",
                templateOptions: {
                    label: "Sample depth (mm)",
                    required: true,
                    type: "number",
                    min: 0
                }
            },
            {
                className: "flex-100",
                type: "textarea",
                key: "notes",
                id: "fms_text_area",
                templateOptions: {
                    label: "Notes",
                    placeholder: "Notes...",
                    rows: 3
                }
            }
        ];

        this.title = this.sample.id ? "Edit soil sample" : "Add soil sample";

        this.save = () => {
            $mdDialog.hide(this.sample);
        };

        this.cancel = () => {
            $mdDialog.cancel();
        };

    }
    
    this.showForSample = function(sample) {
        sample = angular.copy(sample);
        let defer = $q.defer();

        $mdDialog.show({
            controller: controller,
            controllerAs: '$ctrl',
            bindToController: true,
            templateUrl: '/static/assets/khapps/fms_app/myfarm/form/soil/sample/soil-sample-edit-dialog.html',
            parent: angular.element(document.body),
            clickOutsideToClose: false,
            escapeToClose: true,
            locals: {
                sample: sample,
            }
        }).then( (edit) => {
            let ret;
            if (sample.id) {
                // update
                ret = SoilSampleResource.update(edit);
            } else {
                // save
                ret = SoilSampleResource.save(edit);
            }
            ret.$promise.then( (saved) => {
                defer.resolve(saved); 
            }, ErrorLoggingService.genericAPIError);
        });
        
        return defer.promise;
    };
    
});
