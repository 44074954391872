angular.module('FmsApp').factory('PlotResource', function($resource) {
  return $resource(
    '/api/plot/:id',
    { search: '@search' },
    {
      permission: {
        method: 'GET',
        url: '/api/plot/:id/permission'
      }
    }
  );
});

angular.module('FmsApp').factory('PlotSummaryResource', function($resource) {
  return $resource('/api/plot/summary/', {}, {});
});

angular.module('FmsApp').factory('PlotHasSatelliteImageryResource', function($resource){
      return $resource('/api/plot/:id/has-satellite-subscription/');
    })