angular.module('FmsApp').component('soilReport', {
    templateUrl: '/static/assets/khapps/fms_app/myfarm/form/soil/report/soil-report.component.html',
    bindings: {
        report: '<'
    }, 
    controller: function(FieldResource, SoilSampleResource, LaboratoryResource,
                         SoilReportDialogService,
                         KisanHubIconsResource) {
        this.khIcon = KisanHubIconsResource;
        this.lab = LaboratoryResource.get({id: this.report.laboratory_id});
        SoilSampleResource.get({id: this.report.sample_id}).$promise.then( (sample) => {
            this.sample = sample;  
            // use the newer field API
            this.field = FieldResource.get({id: this.sample.field_id});
        });
    }
});
