import * as _ from "underscore";

angular.module('FmsApp').service('SoilReportDialogService', function ($mdDialog, $q, KisanHubIconsResource,
                                                                      SoilReportResource,
                                                                      SoilReportUpdateService,
                                                                      ErrorLoggingService,
                                                                      UploadService,
                                                                      SoilSampleResource, LaboratoryResource) {

    this.showForId = (id) => {
        SoilReportResource.get({id:id}).$promise.then( (report)=> {
           this.show(report);
        });
    };

    this.show = (report) => {
        $mdDialog.show({
            controller: function() {
                this.khIcon = KisanHubIconsResource.khIcons;

                if (report && report.sample_id) {
                    SoilSampleResource.get({id: report.sample_id}).$promise.then( (sample) => {
                        this.selectedSample = sample;
                        this.sampleSearchText = this.selectedSample.name;
                    });
                }
                if (report && report.attachments) {
                    this.attachments = angular.copy(report.attachments);
                } else {
                    this.attachments = [];
                }

                LaboratoryResource.query({'category': 'soil'}).$promise.then((labs) => {
                    this.labs = labs;
                    if (labs.length === 1) {
                        this.report.laboratory_id = labs[0].id;
                    }
                });

                this.attachmentAdded = (file) => {
                    this.attachments.push(file);
                }

                this.attachmentRemoved = (file) => {
                    this.attachments = _.reject(this.attachments,
                        f => { return f.url === file.url });
                }

                this.sampleSearch = (search) => {
                    let deferred = $q.defer();
                    SoilSampleResource.get({
                        'search': this.sampleSearchText,
                        'exclude_with_reports': true
                    }).$promise.then((resp) => {
                        deferred.resolve(resp.results);
                    });
                    return deferred.promise;
                };
                this.fields = {
                    physical: [{
                        className: 'flex-25',
                        key: 'physical_properties.sand',
                        type: 'input',
                        templateOptions: {
                            label: 'Sand (%)',
                            type: 'number',
                            min: 0,
                            max: 100
                        }
                    }, {
                        className: 'flex-25',
                        key: 'physical_properties.silt',
                        type: 'input',
                        templateOptions: {
                            label: 'Silt (%)',
                            type: 'number',
                            min: 0,
                            max: 100
                        }
                    }, {
                        className: 'flex-25',
                        key: 'physical_properties.clay',
                        type: 'input',
                        templateOptions: {
                            label: 'Clay (%)',
                            type: 'number',
                            min: 0,
                            max: 100
                        }
                    }, {
                        className: 'flex-25',
                        key: 'physical_properties.texture',
                        type: 'input',
                        templateOptions: {
                            label: 'Texture',
                            placeholder: 'USDA textural class'
                        }
                    }, {
                        className: 'flex-33',
                        key: 'physical_properties.colour',
                        type: 'input',
                        templateOptions: {
                            label: 'Colour',
                            placeholder: 'Colour of soil'
                        }
                    }, {
                        className: 'flex-33',
                        key: 'physical_properties.structure',
                        type: 'input',
                        templateOptions: {
                            label: 'Structure',
                            placeholder: 'Structure of soil'
                        }
                    }, {
                        className: 'flex-33',
                        key: 'physical_properties.bulk_density',
                        type: 'input',
                        templateOptions: {
                            label: 'Bulk Density (g/cc)',
                            type: 'number',
                            min: 0,
                            placeholder: 'Bulk Density of soil'
                        }
                    }, {
                        className: 'flex-33',
                        key: 'physical_properties.moisture_content',
                        type: 'input',
                        templateOptions: {
                            label: 'Moisture Content (%)',
                            type: 'number',
                            min: 0,
                            max: 100,
                            placeholder: 'Moisture content of soil (%)'
                        }
                    }, {
                        className: 'flex-33',
                        key: 'physical_properties.field_capacity',
                        type: 'input',
                        templateOptions: {
                            label: 'Field Capacity (%)',
                            type: 'number',
                            min: 0,
                            max: 100,
                            placeholder: 'Moisture content at field capacity (%)'
                        }
                    }, {
                        className: 'flex-33',
                        key: 'physical_properties.permanent_wilting_point',
                        type: 'input',
                        templateOptions: {
                            label: 'Permanent Wilting Point (%)',
                            type: 'number',
                            min: 0,
                            max: 100,
                            placeholder: 'Moisture content at permanent wilting point (%)'
                        }
                    }, {
                        className: 'flex-33',
                        key: 'physical_properties.percolation_rate',
                        type: 'input',
                        templateOptions: {
                            label: 'Percolation Rate (cm/hr)',
                            type: 'number',
                            min: 0,
                            placeholder: 'Percolation rate (cm/hr)'
                        }
                    }, {
                        className: 'flex-33',
                        key: 'physical_properties.hydraulic_conductivity',
                        type: 'input',
                        templateOptions: {
                            label: 'Hydraulic Conductivity (cm/day)',
                            type: 'number',
                            min: 0,
                            placeholder: 'Hydraulic conductivity (cm/day)'
                        }
                    }, {
                        className: 'flex-33',
                        key: 'physical_properties.plastic_limit',
                        type: 'input',
                        templateOptions: {
                            label: 'Plastic Limit (%)',
                            type: 'number',
                            min: 0,
                            max: 100,
                            placeholder: 'Moisture content at plastic limit (%)'
                        }
                    }],
                    chemical: [{
                        className: 'flex-50',
                        key: 'chemical_properties.ph',
                        type: 'input',
                        templateOptions: {
                            label: 'pH',
                            placeholder: 'pH',
                            type: 'number',
                            min: 0,
                            max: 14
                        }
                    }, {
                        className: 'flex-50',
                        key: 'chemical_properties.electrical_conductivity',
                        type: 'input',
                        templateOptions: {
                            label: 'Electrical Conductivity (microsiemens/cm)',
                            placeholder: 'Electrical Conductivity (microsiemens/cm)',
                            type: 'number',
                            min: 0
                        }
                    }, {
                        className: 'flex-50',
                        key: 'chemical_properties.cation_exchange_capacity',
                        type: 'input',
                        templateOptions: {
                            label: 'Cation Exchange Capacity (meq/100 gms)',
                            placeholder: 'Cation exchange capacity (meq/100 gms)',
                            type: 'number',
                            min: 0
                        }
                    }, {
                        className: 'flex-50',
                        key: 'chemical_properties.sodium_adsorption_ratio',
                        type: 'input',
                        templateOptions: {
                            label: 'Soil adsorption ratio',
                            placeholder: 'Soil adsorption ratio',
                            type: 'number',
                            min: 0
                        }
                    }, {
                        className: 'flex-50',
                        key: 'chemical_properties.organic_matter',
                        type: 'input',
                        templateOptions: {
                            label: 'Organic Matter (%)',
                            placeholder: 'Organic matter (%)',
                            type: 'number',
                            min: 0,
                            max: 100
                        },
                    }, {
                        className: 'flex-50',
                        key: 'chemical_properties.organic_carbon',
                        type: 'input',
                        templateOptions: {
                            label: 'Organic Carbon (%)',
                            placeholder: 'Organic carbon (%)',
                            type: 'number',
                            min: 0,
                            max: 100
                        }
                    }],
                    notes: [{
                        className: 'flex-100',
                        type: 'ckeditor',
                        key: 'notes'
                    }],
                    micro_nutrient: [{
                        className: 'flex-25',
                        key: 'micro_nutrients.iron',
                        type: 'input',
                        templateOptions: {
                            label: 'Fe (ppm)',
                            placeholder: 'Iron (ppm)',
                            type: 'number',
                            min: 0
                        }
                    }, {
                        className: 'flex-25',
                        key: 'micro_nutrients.molybdenum',
                        type: 'input',
                        templateOptions: {
                            label: 'Mo (ppm)',
                            placeholder: 'Molybdenum (ppm)',
                            type: 'number',
                            min: 0
                        }
                    }, {
                        className: 'flex-25',
                        key: 'micro_nutrients.boron',
                        type: 'input',
                        templateOptions: {
                            label: 'B (ppm)',
                            placeholder: 'Boron (ppm)',
                            type: 'number',
                            min: 0
                        }
                    }, {
                        className: 'flex-25',
                        key: 'micro_nutrients.copper',
                        type: 'input',
                        templateOptions: {
                            label: 'Cu (ppm)',
                            placeholder: 'Copper (ppm)',
                            type: 'number',
                            min: 0
                        }
                    }, {
                        className: 'flex-25',
                        key: 'micro_nutrients.manganese',
                        type: 'input',
                        templateOptions: {
                            label: 'Mn (ppm)',
                            placeholder: 'Manganese (ppm)',
                            type: 'number',
                            min: 0
                        }
                    }, {
                        className: 'flex-25',
                        key: 'micro_nutrients.sodium',
                        type: 'input',
                        templateOptions: {
                            label: 'Na (ppm)',
                            placeholder: 'Sodium (ppm)',
                            type: 'number',
                            min: 0
                        }
                    }, {
                        className: 'flex-25',
                        key: 'micro_nutrients.zinc',
                        type: 'input',
                        templateOptions: {
                            label: 'Zn (ppm)',
                            placeholder: 'Zinc (ppm)',
                            type: 'number',
                            min: 0
                        }
                    }, {
                        className: 'flex-25',
                        key: 'micro_nutrients.nickel',
                        type: 'input',
                        templateOptions: {
                            label: 'Ni (ppm)',
                            placeholder: 'Nickel (ppm)',
                            type: 'number',
                            min: 0
                        }
                    }, {
                        className: 'flex-25',
                        key: 'micro_nutrients.chlorine',
                        type: 'input',
                        templateOptions: {
                            label: 'Cl (ppm)',
                            placeholder: 'Chlorine (ppm)',
                            type: 'number',
                            min: 0
                        }
                    }, {
                        className: 'flex-25',
                        key: 'micro_nutrients.cobalt',
                        type: 'input',
                        templateOptions: {
                            label: 'Co (ppm)',
                            placeholder: 'Cobalt (ppm)',
                            type: 'number',
                            min: 0
                        }
                    }, {
                        className: 'flex-25',
                        key: 'micro_nutrients.aluminium',
                        type: 'input',
                        templateOptions: {
                            label: 'Al (ppm)',
                            placeholder: 'Aluminium (ppm)',
                            type: 'number',
                            min: 0
                        }
                    }, {
                        className: 'flex-25',
                        key: 'micro_nutrients.silicon',
                        type: 'input',
                        templateOptions: {
                            label: 'Si (ppm)',
                            placeholder: 'Silicon (ppm)',
                            type: 'number',
                            min: 0
                        }
                    }],
                    macro_nutrient: [{
                        className: 'flex-33',
                        key: 'macro_nutrients.available_nitrogen',
                        type: 'input',
                        templateOptions: {
                            label: 'N (ppm)',
                            placeholder: 'Available Nitrogen (ppm)',
                            type: 'number',
                            min: 0
                        }
                    }, {
                        className: 'flex-33',
                        key: 'macro_nutrients.available_phosphorus',
                        type: 'input',
                        templateOptions: {
                            label: 'P (ppm)',
                            placeholder: 'Available Phosphorus (ppm)',
                            type: 'number',
                            min: 0
                        }
                    }, {
                        className: 'flex-33',
                        key: 'macro_nutrients.available_potassium',
                        type: 'input',
                        templateOptions: {
                            label: 'K (ppm)',
                            placeholder: 'Available Potassium (ppm)',
                            type: 'number',
                            min: 0
                        }
                    }, {
                        className: 'flex-33',
                        key: 'macro_nutrients.magnesium',
                        type: 'input',
                        templateOptions: {
                            label: 'Mg (ppm)',
                            placeholder: 'Magnesium (ppm)',
                            type: 'number',
                            min: 0
                        }
                    }, {
                        className: 'flex-33',
                        key: 'macro_nutrients.calcium',
                        type: 'input',
                        templateOptions: {
                            label: 'Ca (ppm)',
                            placeholder: 'Calcium (ppm)',
                            type: 'number',
                            min: 0
                        }
                    }, {
                        className: 'flex-33',
                        key: 'macro_nutrients.sulphur',
                        type: 'input',
                        templateOptions: {
                            label: 'S (ppm)',
                            placeholder: 'Sulphur (ppm)',
                            type: 'number',
                            min: 0
                        }
                    }]
                };

                this.submit = ()=> {
                    this.report.sample_id = this.selectedSample.id;
                    this.report.attachments = this.attachments;
                    let resource;
                    if (this.report.id) {
                        resource = SoilReportResource.update(this.report);
                    } else {
                        resource = SoilReportResource.save(this.report);
                    }
                    resource.$promise.then((result) => {
                        this.cancel();
                        SoilReportUpdateService.updatedReportId$.next(result.id);
                    }, ErrorLoggingService.genericAPIError);
                };
                this.cancel = () => {
                    $mdDialog.cancel();
                }
            },
            controllerAs: '$ctrl',
            bindToController: true,
            templateUrl: '/static/assets/khapps/fms_app/myfarm/form/soil/report/soil-report-dialog.html',
            locals: {
                report: report,
            }
        });
    };
});
