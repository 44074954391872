/**
 * Wrapper for the leaflet-base MyFarm Map
 *
 * Fetches data via the new optimised `/api/entity/geojson/` endpoints
 * Reponsible for drawing, zooming.
 *
 * Communication for events is handled via RxJS Observables
 */

import leafletPip from 'leaflet-pip';

angular
  .module('FmsApp')
  .service('MyFarmMapService', function(
    $resource,
    $khMessage,
    $rootScope,
    GeoUtilsService,
    MyFarmCachingMapDataService,
    PlatformSettingsService,
    FarmFiltersService,
    leafletData
  ) {
    var self = this;

    self.zoom = null;
    self.geoLayers = {};
    self.geodata = {};
    self.map = null;
    self.overlays = null;
    self.selectedFeature = null;

    self.drawOverlayLayer = null;
    self.drawnLayer = null;

    self.mainLayerGroup = null;

    /**
     * Dict of feature slug to feature layer
     */
    var feature_slug_to_layer = {};

    /**
     * Dict of field slug to label
     */
    var field_slug_to_label = {};

    var mapId = 'landingMap';

    /**
     * RxJS Observable for marker draw events
     * @type {Rx.Subject}
     */
    this.drawMarker$ = new Rx.Subject();

    /**
     * RxJs Observable for polygon draw events
     * @type {Rx.Subject}
     */
    this.drawPolygon$ = new Rx.Subject();

    /**
     * RxJS Observable for selection events
     * @type {Rx.Subject}
     */
    this.selection$ = new Rx.Subject();

    var createLayerFuns = {
      fieldcrop: createFieldCropLayer,
      farm: createFarmLayer,
      field: createFieldLayer,
      soil_sample: createSoilSampleLayer,
      store: createStoreLayer
    };

    var slugKeyNamesByLayer = {
      field: 'slug',
      fieldcrop: 'slug',
      soil_sample: 'id',
      store: 'slug',
      farm: 'slug'
    };

    //
    // PUBLIC
    //

    /**
     * Full reload and zoom to extent
     */
    this.fullReload = function() {
      this.reload(FarmFiltersService.filter, true, true);
    }

    /**
     * Reload and redraw the map using the new combined geo service
     *
     * @param filter - this filter will come from the FarmFilterService
     * @param ignoreCache - if true then ignore the cache and force a reload
     * @param zoomToExtent - if true then zoom to the extend of all the fields
     * @returns Promise for the data fetch
     */
    this.reload = function(filter, ignoreCache, zoomToExtent) {
      var center = null;
      var zoom = null;
      if (self.map && !zoomToExtent) {
        // record current center & zoom
        center = self.map.getCenter();
        zoom = self.map.getZoom();
        self.map = null;
      }
      self.loading = true;
      // we only need the growing_season for the API
      return MyFarmCachingMapDataService.reload(
        filter.team_slug,
        filter.growing_season,
        ignoreCache,
        filter.team_id
      ).then(function(result) {
        leafletData.getMap(mapId).then(function(map) {
          self.map = map;
          self.geodata = result;
          self.loading = false;

          if (self.mainLayerGroup) {
            removeLayers();
          }

          self.mainLayerGroup = L.layerGroup().addTo(map);

          map.off('draw:created');

          // populate all the layers groups
          _.each(createLayerFuns, function(fun, layerName) {
            var layer = (self.geoLayers[layerName] = fun(result[layerName]));
            self.mainLayerGroup.addLayer(layer);
            if (layerName === 'field') {
              layer.bringToBack();
            }
            if (layerName === 'fieldcrop') {
              layer.bringToFront();
            }
          });

          function drawCreated(e) {
            if (self.drawnLayer) {
              self.removeDrawnLayer();
            }
            var type = e.layerType;
            self.drawnLayer = e.layer;
            if (type === 'marker') {
              GeoUtilsService.getMarkerGeocode(e.layer).then(function(
                resolvedGeocode
              ) {
                self.drawMarker$.onNext({
                  layer: e.layer,
                  geocode: resolvedGeocode
                });
              });
            } else if (type === 'polygon') {
              self.drawPolygon$.onNext({ layer: e.layer });
            }
          }

          if (!self.drawOverlayLayer) {
            leafletData.getLayers().then(function(baselayers) {
              self.drawOverlayLayer = baselayers.overlays.draw;
              map.on('draw:created', drawCreated);
            });
          }

          if (self.zoom) {
            self.zoomToFeature(self.zoom.type, self.zoom.slug);
            self.zoom = null;
          } else {
            if (center && zoom) {
              self.map.setView(center, zoom);
            } else {
              // and zoom if required
              if (filter.field_slug && filter.field_slug !== 'All Fields') {
                self.zoomToField(filter.field_slug);
              } else if (filter.farm_slug && filter.farm_slug !== 'All Farms') {
                self.zoomToFarm(filter.farm_slug);
              } else {
                // no zoom - do we have any farms or fields?
                if (self.geodata.field.features.length) {
                  // zoom to extent of fields
                  zoomToBoundingBox(self.geoLayers.field);
                } else {
                  // no fields - do we have a default zoom?
                  zoomToDefault();
                }
              }
            }
          }
        });
      });
    };

    /**
     * Reload a single field crop and update
     * @param slug
     */
    this.reloadFieldCrop = function(slug) {
      MyFarmCachingMapDataService.getSingleCrop(slug).then(function(feature) {
        updateFeature('fieldcrop', feature);
      });
    };

    /**
     * Return the list of all Farms in geojson format
     * @returns {Array} all Farms in geojson format
     */
    this.getFarms = function() {
      return _.map(
        _.pluck(self.geodata.farm['features'], 'properties'),
        function(f) {
          return {
            farm_id: f.id,
            farm_name: f.name,
            slug: f.slug,
            value: f.slug,
            name: f.name
          };
        }
      );
    };

    /**
     * Return the properties of the selected feature
     * @returns {*}
     */
    this.getSelectedProperties = function() {
      return self.selectedFeature.feature.properties;
    };

    /**
     * Remove the currently drawn layer
     */
    this.removeDrawnLayer = function() {
      if (self.drawnLayer) {
        self.drawOverlayLayer.removeLayer(self.drawnLayer);
        self.drawnLayer = null;
      }
    };

    /**
     * Zoom to a particular feature
     * @param type - feature type (e.g farm | field | fieldcrop)
     * @param slug - slug identifier
     */
    this.zoomToFeature = function(type, slug) {
      if (!self.map) {
        // delayed zoom
        self.zoom = { type: type, slug: slug };
      } else {
        switch (type) {
          case 'farm':
            self.zoomToFarm(slug);
            break;
          case 'field':
            self.zoomToField(slug);
            break;
          case 'fieldcrop':
            self.zoomToPlot(slug);
            break;
        }
      }
    };

    /**
     * Zoom to a farm field
     * @param field_slug - field slug
     * @param selected_farm_slug - currently selected farm
     */
    this.zoomToField = function(field_slug, selected_farm_slug) {
      zoomToPlotOrField('field', field_slug, selected_farm_slug);
    };

    /**
     * Zoom to the bounding box of the fields identified by their slugs
     * @param slugs: field slugs
     */
    this.zoomToFields = slugs => {
      let layers = [];
      self.geoLayers.field.eachLayer(layer => {
        if (_.contains(slugs, layer.feature.properties.slug)) {
          layers.push(layer);
        }
      });
      zoomToBoundingBox(L.featureGroup(layers));
    };

    /**
     * Zoom to a field plot
     * @param plot_slug - plot slug
     */
    this.zoomToPlot = function(plot_slug) {
      zoomToPlotOrField('fieldcrop', plot_slug);
    };

    /**
     * Zoom to a farm
     * @param farm_slug - farm slug
     */
    this.zoomToFarm = function(farm_slug) {
      var layer = self.geoLayers['farm'];
      if (layer) {
        if (!farm_slug || farm_slug === 'All Farms') {
          // all farms - zoom out to see all farms
          if (layer.getLayers().length) {
            zoomToBoundingBox(layer);
          } else {
            zoomToDefault();
          }
        } else {
          // find the bounding box for all fields owned by this farm
          var layers = getMatchingLayers(
            self.geoLayers.field,
            'farm_slug',
            farm_slug
          );
          if (layers.length) {
            zoomToBoundingBox(L.featureGroup(layers));
          } else {
            // zoom to farm directly
            const found = findLayerBySlug(farm_slug);
            if (found) {
              self.map.setView(found.getLatLng(), 15);
            }
          }
        }
      }
    };

    /**
     * Search for a specific feature
     * @param layer_name - layer name e.g farm / field / fieldcrop / soil_sample / store
     * @param search_field_name - search field name e.g "plot_slug"
     * @param search_string - search string
     * @returns selected geojson Feature if found
     */
    this.getFeature = function(layer_name, search_field_name, search_string) {
      if (self.geodata && self.geodata[layer_name]) {
        return _.find(self.geodata[layer_name].features, function(feature) {
          return feature.properties[search_field_name] === search_string;
        });
      }
    };

    /**
     * Set the style for the plot layer
     * @param weight_value
     * @param fill_opacity_value
     */
    this.setPlotStyle = function(weight_value, fill_opacity_value) {
      var layer = self.geoLayers['fieldcrop'];
      if (layer) {
        var highlightStyle = {
          weight: weight_value,
          opacity: 1,
          fillOpacity: fill_opacity_value
        };
        layer.bringToFront();
        layer.setStyle(highlightStyle);
      }
    };

    /**
     * Set the style for the field layer
     * @param weight_value
     * @param fill_opacity_value
     */
    this.setFieldStyle = function(weight_value, fill_opacity_value) {
      var layer = self.geoLayers['field'];
      if (layer) {
        var highlightStyle = {
          weight: weight_value,
          opacity: 1,
          dashArray: 10,
          fillOpacity: fill_opacity_value
        };
        layer.bringToBack();
        layer.setStyle(highlightStyle);
      }
    };

    /**
     * Find a point in a named layer
     * @param layerName - layer name e.g field / fieldcrop
     * @param lngLatPoint lng/lat point
     * @return {*} feature if found
     */
    this.findPointInLayer = function(layerName, lngLatPoint) {
      return leafletPip.pointInLayer(
        lngLatPoint,
        self.geoLayers[layerName],
        true
      );
    };

    //
    // Editing
    //

    /**
     * Start editing the selected feature
     * @return {null|*}
     */
    this.editSelectedFeature = function() {
      self.originalFeatureData = self.selectedFeature.feature;
      self.selectedFeature.edited = false;
      self.drawnLayer = self.selectedFeature;
      return self.selectedFeature;
    };

    /**
     * Cancel editing the selected feature
     */
    this.cancelEditedFeature = function() {
      var feature_data = self.originalFeatureData;
      if (self.selectedFeature) {
        self.selectedFeature.editing.disable();
        var layer;
        if (self.selectedFeature.edited) {
          self.map.removeLayer(self.tempMarker);
          self.geoLayers.field.removeLayer(self.tempMarker);
          switch (self.selectedFeature.options.layer) {
            case 'FieldLayer':
              layer = createFieldLayer(feature_data).getLayers()[0];
              self.geoLayers.field.addLayer(layer);
              layer.bringToBack();
              break;
            case 'FieldCropLayer':
              layer = createFieldCropLayer(feature_data).getLayers()[0];
              self.geoLayers.fieldcrop.addLayer(layer);
              layer.bringToFront();
              break;
            default:
              break;
          }
          if (layer) {
            self.tempMarker = self.selectedFeature = layer;
          } else {
            self.selectedFeature.edited = false;
            self.selectedFeature = null;
          }
        }
      }
      self.removeDrawnLayer();
    };

    /**
     * Update a field
     * @param feature - new feature
     */
    this.updateFieldFeature = function(feature) {
      updateDrawnFeature('field', feature);
    };

    /**
     * Update a field crop
     * @param feature - new feature
     */
    this.updateFieldCropFeature = function(feature) {
      updateDrawnFeature('fieldcrop', feature);
    };

    /**
     * Update a farm
     * @param feature - new feature
     */
    this.updateFarmFeature = function(feature) {
      updateDrawnFeature('farm', feature);
    };

    /**
     * Update a soil sample
     * @param feature - new featureq
     */
    this.updateSoilSampleFeature = function(feature) {
      updateDrawnFeature('soil_sample', feature);
    };

    this.updateStoreFeature = function(feature) {
      updateDrawnFeature('store', feature);
    };
    /**
     * Disable edit mode of selected feature
     */
    this.disableEditing = function() {
      if (self.selectedFeature) {
        self.selectedFeature.editing.disable();
        self.removeDrawnLayer();
      }
    };

    //
    // PRIVATE
    //

    /**
     * Update a feature
     * @param layerName
     * @param feature
     */
    function updateFeature(layerName, feature) {
      const slugFieldName = slugKeyNamesByLayer[layerName];
      const slug = feature.properties[slugFieldName];
      const existing = slug
        ? self.getFeature(layerName, slugFieldName, slug)
        : null;
      if (existing) {
        existing.properties = feature.properties;
        existing.geometry = feature.geometry;
        if (layerName === 'field') {
          // recreate label
          createFieldMarkerLabel(existing, feature_slug_to_layer[slug]);
        }
      } else {
        var layer = createLayerFuns[layerName](feature).getLayers()[0];
        self.geodata[layerName].features.push(feature);
        self.geoLayers[layerName].addLayer(layer);
      }
    }

    /**
     * Update or create a drawn map feature.
     * @param layerName
     * @param feature
     */
    function updateDrawnFeature(layerName, feature) {
      updateFeature(layerName, feature);
      self.removeDrawnLayer();
      if (self.selectedFeature && self.selectedFeature.editing) {
        self.selectedFeature.editing.disable();
      }
    }

    /**
     * Zoom to the default
     */
    function zoomToDefault() {
      if (angular.isDefined(PlatformSettingsService.DEFAULT_CENTER_LAT)) {
        self.map.setView(
          [
            PlatformSettingsService.DEFAULT_CENTER_LAT,
            PlatformSettingsService.DEFAULT_CENTER_LONG
          ],
          PlatformSettingsService.DEFAULT_CENTER_ZOOM
        );
      }
    }

    /**
     * Zoom to a plot. If "All fields" selected then zoom to the outer extent of the fields
     * @param layerName - either "field" or "fieldcrop"
     * @param slug - slug
     * @param selectedFarmSlug - currently selected farm
     */
    function zoomToPlotOrField(layerName, slug, selectedFarmSlug) {
      var layer = self.geoLayers[layerName];
      if (layer) {
        if (slug === 'All Fields') {
          // we must have a farm selected: zoom to bounding box of all fields in the farm
          self.zoomToFarm(selectedFarmSlug);
        } else {
          // zoom to field directly
          zoomToBoundingBox(findLayerBySlug(slug));
        }
      }
    }

    /**
     * Zoom to a feature in response to a (click) event
     * @param event
     */
    function simpleZoomToFeature(event) {
      self.map.setView(
        [event.target._latlng.lat, event.target._latlng.lng],
        18
      );
    }

    /**
     * Select a feature in response to a (click) event
     * @param event - event
     */
    function selectFeatureFromEvent(event) {
      var feature = event.target;
      self.selectedFeature = self.originalFeature = feature;
      // notify the observable channel
      self.selection$.onNext(feature);
    }

    /**
     * Zoom to a bounded feature given an event
     * @param event - target event
     */
    function zoomToBoundedFeature(event) {
      self.map.fitBounds(event.target.getBounds(), { padding: [100, 100] });
      self.tempMarker = this;
      selectFeatureFromEvent(event);
    }

    /**
     * Zoom to the bounding box of a feature
     * @param filter_field_feature
     */
    function zoomToBoundingBox(filter_field_feature) {
      if (filter_field_feature) {
        var bounds = filter_field_feature.getBounds();
        if (typeof bounds !== 'undefined') {
          var map = self.map;
          map.fitBounds(bounds);
          // map.invalidateSize();
          // setTimeout(map.invalidateSize.bind(map));
          // map.zoomIn();
          // map.zoomOut();
        }
      }
    }

    /**
     * Return a list of layers which satisfy a property match
     * @param layerGroup - layer group
     * @param key - key to match e.g "farm_slug"
     * @param value - value to match e.g a slug value
     * @return {Array} list of Layers
     */
    function getMatchingLayers(layerGroup, key, value) {
      var ret = [];
      layerGroup.eachLayer(function(layer) {
        if (layer.feature.properties[key] === value) {
          ret.push(layer);
        }
      });
      return ret;
    }

    /**
     * Find a layer by slug
     * @param slug - slug
     * @return {*} feature layer if found
     */
    function findLayerBySlug(slug) {
      return feature_slug_to_layer[slug];
    }

    /**
     * Remove all layers
     */
    function removeLayers() {
      if (self.mainLayerGroup) {
        self.mainLayerGroup.clearLayers();
      }
      self.geoLayers = {};
      self.drawOverlayLayer = null;
      self.drawnLayer = null;
    }

    /* =================================================================================

       Layer creation

       =================================================================================
    */

    /**
     * Create a cluster layer for the given layer
     * @param baseLayer - base layer
     * @param classPrefix - classs prefix for cluster nodes
     * @return {*} generated markers for cluster
     */
    function createGenericClusterLayer(baseLayer, classPrefix) {
      var markers = L.markerClusterGroup({
        showCoverageOnHover: true,
        zoomToBoundsOnClick: true,
        disableClusteringAtZoom: 15,
        spiderfyOnMaxZoom: true,
        removeOutsideVisibleBounds: true,
        animateAddingMarkers: true,
        iconCreateFunction: function(cluster) {
          var clusterSize = 'small';
          if (cluster.getChildCount() >= 50) {
            clusterSize = 'medium';
          }
          if (cluster.getChildCount() >= 100) {
            clusterSize = 'large';
          }
          var p = classPrefix + '-marker-cluster';
          return new L.DivIcon({
            html: '<span>' + cluster.getChildCount() + '</span>',
            className: p + ' ' + p + '-' + clusterSize,
            iconSize: new L.Point(40, 40)
          });
        }
      });
      markers.addLayer(baseLayer);
      return markers;
    }

    /**
     * Load the field layer
     * @param data
     */
    function createFieldLayer(data) {
      var getStyle = {
        weight: 2,
        opacity: 1,
        stroke: 'black',
        dashArray: 10,
        fillOpacity: 0.1
      };

      function onEachFeature(feature, layer) {
        feature.properties.bounds_calculated = layer.getBounds().toBBoxString();
        layer.on({ click: zoomToBoundedFeature });
        createFieldMarkerLabel(feature, layer);
        feature_slug_to_layer[feature.properties.slug] = layer;
      }

      return L.geoJson(data, {
        layer: 'FieldLayer',
        style: getStyle,
        onEachFeature: onEachFeature
      });
    }

    /**
     * Create a divIcon marker label for the given field
     * @param layer - new field layer
     * @param feature - field feature
     */
    function createFieldMarkerLabel(feature, layer) {
      var slug = feature.properties.slug;
      if (field_slug_to_label[slug]) {
        self.mainLayerGroup.removeLayer(field_slug_to_label[slug]);
      }
      var marker = L.marker(layer.getBounds().getCenter(), {
        icon: L.divIcon({
          className: 'field-label',
          html: '<span>' + layer.feature.properties.name + '</span>',
          iconSize: [100, 50]
        })
      }).addTo(self.mainLayerGroup);
      field_slug_to_label[slug] = marker;
    }

    /**
     * Create farm layers
     * @param data
     */
    function createFarmLayer(data) {
      function onEachFeature(feature, layer) {
        layer.on({ click: simpleZoomToFeature });
        feature_slug_to_layer[feature.properties.slug] = layer;
      }

      function pointToLayerMarkers(feature, latlng) {
        var marker;
        var activeIcon = L.AwesomeMarkers.icon({
          prefix: 'fa', //font awesome rather than bootstrap
          markerColor: 'green', // see colors above
          icon: 'leaf' //http://fortawesome.github.io/Font-Awesome/icons/
        });
        marker = new L.marker(latlng, {
          icon: activeIcon,
          layer: 'FarmLayer',
          title: feature.properties.name
        });

        marker.on('click', selectFeatureFromEvent);
        return marker;
      }

      var layer = L.geoJson(data, {
        layer: 'FarmLayer',
        onEachFeature: onEachFeature,
        pointToLayer: pointToLayerMarkers
      });
      return createGenericClusterLayer(layer, 'farm');
    }

    /**
     * Create soil sample layers
     * @param data
     */
    function createSoilSampleLayer(data) {
      function onEachFeature(feature, layer) {
        layer.on({ click: simpleZoomToFeature });
        feature_slug_to_layer[feature.properties.soil_sample_slug] = layer;
      }

      function pointToLayerMarkers(feature, latlng) {
        var marker;
        var activeIcon = L.AwesomeMarkers.icon({
          prefix: 'fa', //font awesome rather than bootstrap
          markerColor: 'darkred', // see colors above
          icon: 'leaf' //http://fortawesome.github.io/Font-Awesome/icons/
        });
        marker = new L.marker(latlng, {
          icon: activeIcon,
          title: 'SoilSampleLayer'
        });

        marker.on('click', selectFeatureFromEvent);
        return marker;
      }

      var layer = L.geoJson(data, {
        layer: 'SoilSampleLayer',
        onEachFeature: onEachFeature,
        pointToLayer: pointToLayerMarkers
      });

      return createGenericClusterLayer(layer, 'soil-sample');
    }

    /**
     * Create store layers
     * @param data
     */
    function createStoreLayer(data) {
      function onEachFeature(feature, layer) {
        layer.on({ click: simpleZoomToFeature });
        feature_slug_to_layer[feature.properties.slug] = layer;
      }

      function pointToLayerMarkers(feature, latlng) {
        var marker;
        var activeIcon = L.AwesomeMarkers.icon({
          prefix: 'fa', //font awesome rather than bootstrap
          markerColor: 'orange', // see colors above
          icon: 'cube' //http://fortawesome.github.io/Font-Awesome/icons/
        });
        marker = new L.marker(latlng, {
          icon: activeIcon,
          title: 'StoreLayer'
        });

        marker.on('click', selectFeatureFromEvent);
        return marker;
      }

      var layer = L.geoJson(data, {
        layer: 'StoreLayer',
        onEachFeature: onEachFeature,
        pointToLayer: pointToLayerMarkers
      });

      return createGenericClusterLayer(layer, 'store');
    }

    /**
     * Create field plot layers (i.e "FieldPlotCrop")
     * @param data
     */
    function createFieldCropLayer(data) {
      var getStyle = function(feature) {
        var fillcolor = MyFarmCachingMapDataService.getGenusColourCode(
          feature.properties.genus_id || feature.properties.crop_id
        );
        return {
          color: fillcolor,
          fillColor: fillcolor,
          weight: 4,
          opacity: 1,
          fillOpacity: 0.7
        };
      };

      function onEachFeature(feature, layer) {
        feature.properties.bounds_calculated = layer.getBounds().toBBoxString();
        layer.on({ click: zoomToBoundedFeature });
        feature_slug_to_layer[feature.properties.plot_slug] = layer;
      }

      return L.geoJson(data, {
        layer: 'FieldCropLayer',
        onEachFeature: onEachFeature,
        style: getStyle
      });
    }
  });
