angular.module('FmsApp')
.component('slugSelect', {
    bindings: {
        model: '<',
        options: '<',
        onSelect: '&',
        title: '@',
        placeholder: '@'
    },
    templateUrl: '/static/assets/khapps/fms_app/myfarm/form/slug-select.component.html',
    controller: function($element) {
        var self=this;
        this.$postLink = function() {
            // The md-select directive eats keydown events for some quick select
            // logic. Since we have a search input here, we don't need that logic.
            $element.find('input').on('keydown', function (ev) {
                ev.stopPropagation();
            });
        }
    }
});
