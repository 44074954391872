angular.module('FmsApp').service("StoreEditDialogService", function ($mdDialog, $khMessage,
                                                        MyFarmMapService, FarmConstantsService,
                                                        ErrorLoggingService) {

    function controller(StoreFactory)
    {
        var self=this;

        var store = self.store;
        self.store_record = store;

        self.form_fields = [
            {
                className: "flex-100",
                type: 'select',
                key: "team",
                templateOptions: {
                    label: "Team",
                    required: true,
                    placeholder: "Select team",
                    options: FarmConstantsService.teams,
                    valueProp: 'value',
                    labelProp: 'name'
                }
            },
            {
                className: "flex-100",
                type: "input",
                key: "name",
                id: "store_name",
                templateOptions: {
                    label: "Store Name",
                    required: true,
                    placeholder: "Store name"
                }
            },
            {
                className: "flex-100",
                type: "input",
                key: "location",
                id: "store_location",
                templateOptions: {
                    label: "Town/Location",
                    required: true,
                    placeholder: "Town/location"
                }
            },
            {
                className: "flex-100",
                type: 'select',
                key: "store_type",
                templateOptions: {
                    label: "Store Type",
                    required: true,
                    placeholder: "Store type",
                    options: FarmConstantsService.store_types,
                    valueProp: 'value',
                    labelProp: 'name'
                },
                expressionProperties: {
                    "templateOptions.disabled": "model.slug"
                }

            },
            {
                className: "flex-100",
                type: 'select',
                key: "storage_type",
                id: "storage_type",
                templateOptions: {
                    label: "Storage Type",
                    placeholder: "Storage type",
                    options: FarmConstantsService.cold_storage_types,
                    valueProp: 'value',
                    labelProp: 'name'
                },
                hideExpression: '!model.store_type || !["ambient", "cold"].includes(model.store_type)'
            },
            {
                className: "flex-50",
                type: "input",
                key: "capacity_value",
                templateOptions: {
                    label: "Capacity Value",
                    placeholder: "Capacity value",
                    type: "number",
                    min: 0
                }
            },
            {
                className: "flex-50",
                type: 'select',
                key: "capacity_unit",
                templateOptions: {
                    label: "Capacity Unit",
                    placeholder: "Capacity unit",
                    options: FarmConstantsService.capacity_units,
                    labelProp: 'name',
                    valueProp: 'value'

                }
            }];
        self.title = store.slug ? "Edit store sample" : "Add store";

        self.save = function () {
            if (self.geocoding) {
                store.latitude = self.geocoding.latitude;
                store.longitude = self.geocoding.longitude;
            }

            var promise;
            if (store.slug) {
                promise = StoreFactory.update({store_id: store.id, "data-format": "geojson"}, store).$promise;
            } else {
                promise = StoreFactory.save({"data-format": "geojson"}, store).$promise;
            }

            promise.then(function(feature) {
                $mdDialog.hide(feature);
            }, function(error) {
                ErrorLoggingService.log("Failed to save store from map:" +error);
                $khMessage.show("Sorry - we failed to store the information", 3000);
            });
        };

        self.cancel = function () {
            $mdDialog.cancel();
        };


    }

    this.show = function (store, geocoding) {
        var promise = $mdDialog.show({
            controller: controller,
            controllerAs: '$ctrl',
            bindToController: true,
            templateUrl: '/static/assets/khapps/fms_app/myfarm/form/store/store-edit-dialog.html',
            parent: angular.element(document.body),
            clickOutsideToClose: false,
            escapeToClose: true,
            locals: {
                store: store,
                geocoding: geocoding
            }
        });
        promise.then(function(feature) {
            MyFarmMapService.updateStoreFeature(feature);
        }).finally(function () {
            MyFarmMapService.removeDrawnLayer();
        });
        return promise;
    };
});
