(function (angular) {
    'use strict';
    angular.module('GeoDataExportApp')
        .config(['$routeProvider', '$resourceProvider', '$locationProvider', GeoDataExportConfig]);

    // GeoDataExportConfig.$inject = ['$routeProvider', '$resourceProvider', '$locationProvider', GeoDataExportConfig];

    function GeoDataExportConfig($routeProvider, $resourceProvider, $locationProvider) {
        $resourceProvider.defaults.stripTrailingSlashes = false;
    }

})(angular);