import {DeleteFieldService} from "../../../../../../angular/src/app/farm/delete-field.service";

angular.module('FmsApp').component('fieldSidebar', {
  templateUrl:
    '/static/assets/khapps/fms_app/myfarm/map/sidebar/field/field-sidebar.component.html',
  bindings: {
    field: '<',
    editClick: '&',
    fieldDeleted: '&',
    notifyClick: '&',
    subscribeClick: '&'
  },
  controller: function(
    $q,
    FieldResource,
    PermissionEvaluatorService,
    FarmResource,
    SoilTypeResource,
    TeamResource,
    UserResource,
    $rootScope,
    DeleteFieldService
  ) {
    const self = this;

    self.updatedByUser = {};
    self.farm = {};
    self.team = {};
    self.canEdit = false;
    self.soilType = {};

    self.is_superuser = $rootScope.is_superuser === 'True';

    this.$onChanges = () => {
      $q.all([
        FieldResource.get({ id: self.field.id }).$promise,
        FieldResource.permission({ id: self.field.id }).$promise
      ])
        .then(function(responses) {
          let field = responses[0];
          let permission = responses[1];

          self.field = field;
          self.canEdit = PermissionEvaluatorService.isReadWrite(
            permission.access
          );

          return $q.all([
            UserResource.get({ id: field.updated_by_id }).$promise,
            FarmResource.get({ id: field.farm_id }).$promise,
            SoilTypeResource.get({ id: field.soil_type_id }).$promise
          ]);
        })
        .then(function([user, farm, soilType]) {
          self.updatedByUser = user;
          self.farm = farm;
          self.soilType = soilType;
          return TeamResource.get({ id: farm.team_id }).$promise;
        })
        .then(function(response) {
          self.team = response;
        });
    };

    this.onDeleteClick = function(id) {
      DeleteFieldService.checkDeleteField(id).subscribe( (deleted) => {
        if (deleted) {
          self.fieldDeleted({id});
        }
      });
    };
    this.onNotifyClick = function(slug, name) {
      if (angular.isFunction(self.notifyClick)) {
        self.notifyClick({ slug, name });
      }
    };
    this.onEditClick = function() {
      if (angular.isFunction(self.editClick)) {
        self.editClick();
      }
    };
    this.onSubscribeClick = function(field) {
      if (angular.isFunction(self.subscribeClick)) {
        self.subscribeClick({ field });
      }
    };
  }
});
