// var farmEquipmentForm = farmEquipmentForm || {};
//
// farmEquipmentForm =

angular.module('FmsApp').service("FarmEquipmentFormService", function () {

    this.farmEquipmentForm = function (scope) {
        return [{
            className: "flex-100",
            key: 'team_id',
            type: 'searchable_select',
            templateOptions: {
                label: "Team",
                required: true,
                options: scope.teams,
                labelProp: 'team_name',
                valueProp: 'id'
            }
        }, {
            className: "flex-50",
            key: 'equipment_name',
            type: 'input',
            templateOptions: {
                label: "Name",
                required: true
            }
        }, {
            className: "flex-50",
            key: 'model',
            type: 'input',
            templateOptions: {
                label: "Model",
                required: true
            }
        }, {
            className: "flex-50",
            key: 'type',
            type: 'searchable_select',
            templateOptions: {
                label: "Type",
                options: scope.farm_equipment_types_dict,
                required: true
            }
        }, {
            className: "flex-50",
            key: 'registration_number',
            type: 'input',
            templateOptions: {
                label: "Registration Number"
            }
        }, {
            className: "flex-25",
            key: 'capacity',
            type: 'input',
            templateOptions: {
                label: "Capacity",
                type: "number",
                min: 0
            }
        }, {
            className: "flex-25",
            key: 'capacity_unit',
            type: 'searchable_select',
            templateOptions: {
                label: "Unit",
                options: scope.equipment_units.Volume
            },
            expressionProperties: {
                'templateOptions.required': 'model.capacity'
            }
        }, {
            className: "flex-25",
            key: 'power',
            type: 'input',
            templateOptions: {
                label: "Power",
                type: "number",
                min: 0
            }
        }, {
            className: "flex-25",
            key: 'power_unit',
            type: 'searchable_select',
            templateOptions: {
                label: "Unit",
                options: scope.equipment_units.Power
            },
            expressionProperties: {
                'templateOptions.required': 'model.power'
            }
        }];
    };
});

