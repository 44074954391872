angular.module("KisanAuthApp").factory("KisanAuthTokenServiceFactory", ["$resource", function ($resource) {
    return {
        init: function (appKhAuthBaseUrl) {
            var baseApiUrl = appKhAuthBaseUrl + '/oauth/token/';
            return $resource(baseApiUrl, {}, {
                save: {
                    params: {
                        grant_type: '@grant_type',
                        client_id: '@client_id',
                        client_secret: '@client_secret',
                        scope: '@read_write_scope'
                    },
                    method: 'POST'
                },
            });
        }
    }
}]);