angular.module('FmsApp').component('fieldInventoryAdmin', {
    templateUrl: '/static/assets/khapps/fms_app/myfarm/field_inventory/admin-page.component.html',
    controller: function ($http, $interval) {
        var self=this;
        self.clear = false;
        self.fieldsBuilt = 0;

        var promise = $interval(getStatus, 2000);

        function getStatus() {
            $http.get('/api/fms/fieldcrop/inventory/rebuild/').then(function(resp) {
               self.status = resp.data;
               self.building = (resp.data.status === 'running');
            });
        }

        self.rebuild = function() {
            if (self.building) {
                return;
            }
            var params = {};
            if (self.clear) {
                params.clear = 'true';
            }
            self.building = true;

            $http.post('/api/fms/fieldcrop/inventory/rebuild/', params).then(function() {
               self.status = {'status': 'running', 'msg': 'Starting rebuild'}
            });
        };

        self.$onDestroy = function() {
            if (promise) {
                $interval.cancel(promise);
            }
        }
    }
});