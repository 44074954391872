angular.module('FmsApp').service('FarmFieldDialogService', function($mdDialog, $filter,
                                                                    MyFarmMapService, FarmConstantsService) {
    this.show = function(farmfield) {
        var promise = $mdDialog.show({
            templateUrl: '/static/assets/khapps/fms_app/myfarm/form/field/farm-field-dialog.html',
            parent: angular.element(document.body),
            controller: function (farmfield) {
                this.farmfield = farmfield;
                this.title = this.farmfield.field_slug ? $filter('translate')('common.edit_field') : $filter('translate')('common.add_field');
            },
            controllerAs: '$ctrl',
            bindToController: true,
            locals: {
                farmfield: farmfield
            },
            resolve: {
                // just to make s0ure it's there. It should be as it's loaded at start of day
                consts: function() { return FarmConstantsService.promise }
            }
        });
        promise.then(null, function() {
            MyFarmMapService.cancelEditedFeature();
        });
        return promise;
    }

});
