angular.module('FmsApp').constant('MapSpeedDialOptions', [
    {
        isOpen: false,
        availableModes: ['md-fling', 'md-scale'],
        selectedMode: 'md-scale',
        selectedDirection: 'up',
        availableDirections: ['up', 'down', 'left', 'right'],
        menuIcon: 'plus',
        postion: 'bottom-right',
        submenuList: [
            {
                label: 'Farm',
                tooltip: {
                    label: 'Add Farm',
                    direction: 'left'
                },
                action: 'draw_farm',
                icon: 'leaf'
            },
            {
                label: 'Field',
                tooltip: {
                    label: 'Add Field',
                    direction: 'left'
                },
                action: 'draw_field',
                icon: 'vector-polygon'
            },
            {
                label: 'Crop',
                tooltip: {
                    label: 'Add Crop',
                    direction: 'left'
                },
                action: 'draw_crop',
                icon: 'barley'
            },
            {
                label: 'Soil Sample',
                tooltip: {
                    label: 'Add Soil Sample',
                    direction: 'left'
                },
                action: 'draw_soilsample',
                icon: 'flask'
            },
            {
                label: 'Upload',
                tooltip: {
                    label: 'Upload Data',
                    direction: 'left'
                },
                action: 'data_upload',
                icon: 'upload'
            },
            {
                label: 'Store Location',
                tooltip: {
                    label: 'Add Store',
                    direction: 'left'
                },
                action: 'draw_store',
                icon: 'cube'
            }
        ]
    }
]);
