angular.module('FmsApp').component("myfarmAddButton", {
    templateUrl: '/static/assets/khapps/fms_app/myfarm/navbar/myfarm-add-button.component.html',
    controller: function ($rootScope, $mdDialog, KisanHubIconsResource, FieldBoundaryDialogService,
                          AddVarietyService,
                          SoilReportDialogService, PhoenixService) {
        var self=this;

        self.khIcon = KisanHubIconsResource;

        self.showFarmAppModal = function (item, event) {
            switch (item) {
                case 'profile':
                    $mdDialog.show({
                        templateUrl: '/static/assets/khapps/fms_app/partials/farmdetails/profile/add_edit_farm_profile.html',
                        controller: 'AddEditFarmProfileController',
                        multiple: true,
                        escapeToClose: true,
                        locals: {
                            farm_slug: null
                        }
                    }).then(function (response) {
                        $rootScope.$broadcast("farmProfileAdded", response);
                    });
                    break;
                case 'certificate':
                    $mdDialog.show({
                        templateUrl: '/static/assets/khapps/fms_app/partials/farmdetails/certificate/add_edit_farm_certificate_dialog.html',
                        controller: 'AddEditFarmCertificateController',
                        multiple: true,
                        locals: {
                            record_slug: null
                        }
                    }).then(function (response) {
                        $rootScope.$broadcast("farmCertificateAdded", response);
                    });
                    break;
                case 'emergency_contact':
                    $mdDialog.show({
                        templateUrl: '/static/assets/khapps/fms_app/partials/farmdetails/emergency_contact/add_edit_emergency_contact.html',
                        controller: 'AddEditEmergencyContactController',
                        multiple: true,
                        locals: {
                            farm_slug: null
                        }
                    }).then(function (response) {
                        $rootScope.$broadcast("emergencyContactAdded", response);
                    });
                    break;
                case 'farm_equipment':
                    $mdDialog.show({
                        templateUrl: '/static/assets/khapps/fms_app/partials/farmdetails/equipment/add_edit_farm_equipment_dialog_form.html',
                        controller: 'AddEditFarmEquipmentController',
                        multiple: true,
                        locals: {
                            equipment_slug: null
                        }
                    }).then(function (response) {
                        $rootScope.$broadcast("farmEquipmentAdded", response);
                    });
                    break;
                case 'water_source':
                    $mdDialog.show({
                        templateUrl: '/static/assets/khapps/fms_app/partials/farmdetails/water_source/water_source_dialog_form.html',
                        controller: 'AddEditWaterSourceController',
                        locals: {
                            source_slug: null
                        }
                    }).then(function (response) {
                        $rootScope.$broadcast("waterSourceAdded", response);
                    });
                    break;
                case 'result':
                    SoilReportDialogService.show();
                    break;
                case 'variety':
                    AddVarietyService.open();
                    break;
                case 'seed':
                    $mdDialog.show({
                        templateUrl: '/static/assets/khapps/fms_app/partials/setup/seed/seed_dialog_form.html',
                        controller: 'AddEditSeedController',
                        locals: {
                            seed_slug: null
                        }
                    }).then(function (response) {
                        $rootScope.$broadcast("seedAdded", response);
                    });
                    break;
                case 'upload':
                    FieldBoundaryDialogService.show();
                    break;
                default:
                    alert("Unknown type");
            }
        };
   this.redirectPhoenixOperations = function() {
      PhoenixService.getRefreshToken().then((token) => {
        window.location = `${PhoenixService.getHostUrl}/farm/operation/new/?token=${token}`;
      });
    };
    }
});
